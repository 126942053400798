/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import axios from 'axios';
import { Container, InputGroup } from 'reactstrap';
import { Form, Label, Input, FormGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import config from '../config';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import DatePicker from 'reactstrap-date-picker';
import { Redirect, withRouter } from 'react-router-dom';
import Loading from './Loading';
import { createConversation, createMessage } from '../graphql/mutations';
import UploadFile from '../components/UploadFile';
import { Storage } from 'aws-amplify';
import { MixpanelContext } from '../MixpanelContext';

const { PRODUCTDB_API_URL } = config;

class CreateProposal extends Component {
  static contextType = MixpanelContext;

  constructor(props) {
    super(props);
    this.state = {
      job: null,
      budget: null,
      deadline: null,
      currency: 'USDT',
      details: null,
      walletId: null,
      freelancerUsername: null,
      submitted: null,
      submitLoading: false,
      attachedFiles: [],
      s3UploadData: [],
    };
  }

  componentDidMount = async () => {
    const { username } = await Auth.currentAuthenticatedUser();
    const { jobKey } = this.props.match.params;
    const [jobTs, clientId] = decodeURIComponent(jobKey).split('#');
    // TODO: make it redirect to an unauthorized page: Cant make proposal on your own job
    const res = await axios.get(`${PRODUCTDB_API_URL}/clients/${clientId}/jobs/${jobTs}`);
    const { Job } = res.data;
    this.setState({
      freelancerUsername: username,
      Job,
    });
  };

  handleInputChange = (event) => {
    const { value, name } = event.target;

    this.setState({
      [name]: value,
    });
  };

  handleDateChange = (value) => {
    this.setState({ deadline: value });
  };

  setAttachments = (s3UploadData) => {
    let filesMetaData = [];
    for (let i = 0; i < s3UploadData.length; i++) {
      filesMetaData.push({ name: s3UploadData[i].file.name, location: s3UploadData[i].location });
    }
    this.setState({ attachedFiles: filesMetaData, s3UploadData: s3UploadData });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ submitLoading: true });

    const { budget, deadline, currency, freelancerUsername, details, attachedFiles, s3UploadData, walletId } =
      this.state;
    const { Job } = this.state;

    const user = await Auth.currentUserInfo(); // TODO put this in context
    const freelanncerId = user.attributes.sub;

    const createProposalData = {
      job: Job,
      budget,
      deadline,
      currency,
      freelancerUsername,
      details,
      attachedFiles,
      walletId,
    };

    try {
      for (let i = 0; i < s3UploadData.length; i++) {
        await Storage.put(s3UploadData[i].location, s3UploadData[i].file);
        console.log('uploading to S3 : ', s3UploadData[i]);
      }
      // create propolsal
      const createProposalRes = await axios.post(
        PRODUCTDB_API_URL + `/freelancers/${freelanncerId}/proposals`,
        createProposalData,
      );
      const { createdTs } = createProposalRes.data;

      this.mixpanelFormSubmissionTrigger();

      // current user is freelancer, other user is client
      const currentUsername = user.username;
      const currentUserId = user.attributes.sub;
      const channelID = `${currentUserId}#${createdTs}`;
      const otherUserId = Job.clientId;
      const otherUsername = Job.clientUsername;
      const jobTitle = Job.title;
      const isAccepted = false;

      const createConversationInput = {
        channelID, // freelancerID#proposalTs
        userID: currentUserId,
        username: currentUsername,
        otherUserID: otherUserId,
        otherUsername,
        jobTitle,
        isAccepted,
      };

      const createInverseConversationInput = {
        channelID,
        userID: otherUserId,
        username: otherUsername,
        otherUserID: currentUserId,
        otherUsername: currentUsername,
        jobTitle,
        isAccepted,
      };

      // create conversations in chat (one for current user, one for other user)
      await API.graphql(graphqlOperation(createConversation, { input: createConversationInput }));
      await API.graphql(graphqlOperation(createConversation, { input: createInverseConversationInput }));

      // send initial message to the chat from freelancer
      const createInitialMessageInput = {
        channelID,
        author: currentUserId,
        body: `Hello, please take a look at my proposal on your job ${jobTitle}.`,
        read: false,
      };

      await API.graphql(graphqlOperation(createMessage, { input: createInitialMessageInput }));

      // disable freelancer's ability to send messages until the

      this.setState({
        budget: null,
        deadline: null,
        currency: null,
        freelancerUsername: null,
        details: null,
        submitted: true,
        attachedFiles: [],
      });
    } catch (error) {
      console.log('Couldnt create proposal');
      console.log(error);
    }

    this.setState({ submitLoading: false });
  };

  handleInputChange = (event) => {
    const { value, name } = event.target;

    this.setState({
      [name]: value,
    });
  };

  handleDateChange = (value) => {
    this.setState({ deadline: value });
  };

  mixpanelFormSubmissionTrigger = () => {
    const mixpanel = this.context;
    mixpanel.track('New Proposal Created', {
      freelancer: this.state.freelancerUsername,
      job: this.state.Job.title,
    });
  };

  render() {
    const { Job, budget, deadline, freelancerUsername, submitted, details, submitLoading } = this.state;

    if (submitted) return <Redirect to="/proposals" />;

    if (!Job) return <Loading />;

    const submitDisabled = !budget || !deadline;

    return (
      <Container>
        <h1 style={{ textAlign: "center", marginTop: "20px" }}>Create Proposal</h1>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup style={{ margin: 10 }}>
            <Label for="jobTitle">Job Title: </Label>
            <Input name="title" type="text" id="jobTitle" defaultValue={Job.title} disabled />
          </FormGroup>
          <FormGroup style={{ margin: 10 }}>
            <Label for="jobClient">Client: </Label>
            <Input name="client-name" type="text" id="clientName" defaultValue={Job.clientUsername} disabled />
          </FormGroup>
          <FormGroup style={{ margin: 10 }}>
            <Label for="freelance-username">Freelancer Username: </Label>
            <Input
              disabled
              defaultValue={freelancerUsername}
              required
              name="freelancerUsername"
              type="text"
              id="freelanceUsername"
            />
          </FormGroup>
          <FormGroup style={{ margin: 10 }}>
            <Label for="budget">Budget - <em>Gigoshi takes a 5% fee from the final proposal cost:</em>  </Label>
            <InputGroup>
              <Input onChange={this.handleInputChange} name="budget" type="number" id="freelanceBudget" required />
              <InputGroupAddon addonType="append" style={{ display: 'flex' }}>
                <InputGroupText>USDT</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <FormGroup style={{ margin: 10 }}>
            <Label for="walletId">Wallet ID:  Please add BNB address ONLY!
              <strong>{" Gigoshi is not responsible for any lost transactions."}</strong>
            </Label>
            <Input onChange={this.handleInputChange} required name="walletId" type="text" id="walletId" />
          </FormGroup>
          <FormGroup style={{ margin: 10 }}>
            <Label for="deadline">When can you complete this job by? </Label>
            <DatePicker
              instanceCount={3}
              required
              id="freelance-deadline-datepicker"
              value={deadline}
              onChange={(value) => this.handleDateChange(value)}
              previousButtonElement="Prev"
              nextButtonElement="Next"
              clearButtonElement="Clear"
              showTodayButton={true}
            />
          </FormGroup>
          <FormGroup style={{ margin: 10 }}>
            <Label for="details">Other details ({500 - (details?.length || 0)})</Label>
            <Input
              type="textarea"
              name="details"
              id="details"
              rows="4"
              maxLength="500"
              onChange={this.handleInputChange}
            />
          </FormGroup>
          <div style={{ margin: 10 }}>
            <UploadFile setAttachments={this.setAttachments} />
          </div>
          <FormGroup style={{ margin: 10 }}>
            <Input className="form-submit-btn" type="submit" disabled={submitDisabled || submitLoading} />
          </FormGroup>
        </Form>
      </Container>
    );
  }
}

export default withRouter(CreateProposal);
