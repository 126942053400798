import React, { Component } from 'react';
import axios from 'axios';
import config from '../config';
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardColumns,
  Container,
  Input,
} from 'reactstrap';
import Loading from '../components/Loading';
import Avatar from '../components/Avatar';
import { withRouter } from 'react-router-dom';
import { MixpanelContext } from '../MixpanelContext';

const { PRODUCTDB_API_URL } = config;

class JobSearchPage extends Component {
  static contextType = MixpanelContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: 'null',
      jobs: [],
    };
  }

  componentDidMount = () => {
    const mixpanel = this.context;
    if (mixpanel) {
      this.searchPageTrigger();
    }
  };

  searchPageTrigger = () => {
    const mixpanel = this.context;
    mixpanel.track('Job Search Page Visited', {
      source: 'Gigoshi Jobs Search Page',
    });
  };

  handleChange = async (event) => {
    this.setState({ loading: true });
    const selectedCategory = event.target.value;
    const res = await axios.get(
      PRODUCTDB_API_URL +
        `/category/${encodeURIComponent(selectedCategory)}/jobs`,
    );

    const { Jobs } = res.data;
    console.log('res');
    console.log(res);
    this.setState({
      selectedCategory,
      Jobs,
      loading: false,
      selectedCategoryDisabled: true,
    });
  };

  renderJobs = () => {
    const { Jobs, loading } = this.state;

    if (loading) return <Loading />;

    if (!Jobs) return null;

    if (!Jobs.length)
      return (
        <Container
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '10px',
          }}>
          No Jobs found
        </Container>
      );

    let jobList = [];
    for (let job of Jobs) {
      const {
        title,
        clientUsername,
        description,
        budgetMax,
        currency,
        createdTs,
        clientId,
      } = job;
      const cardDescription =
        description.length <= 100
          ? description
          : description.substring(0, 98) + '...';

      const jobKey = `${createdTs}#${clientId}`;
      const redirectUri = `/jobs/${encodeURIComponent(jobKey)}`;

      jobList.push(
        <CardColumns>
          <Card
            key={createdTs}
            className="job-grid-card"
            style={{ cursor: 'pointer' }}
            onClick={() => this.props.history.push(redirectUri)}>
            <CardBody>
              <CardTitle tag="h5">{title}</CardTitle>
              <CardSubtitle>
                <span>
                  <Avatar userId={clientId} />
                  <span style={{ marginLeft: '3px' }}>{clientUsername}</span>
                </span>
              </CardSubtitle>
              <CardText>{cardDescription}</CardText>
              <CardSubtitle className="job-budget">
                {budgetMax} {currency}
              </CardSubtitle>
            </CardBody>
          </Card>
        </CardColumns>,
      );
    }
    return jobList;
  };

  render() {
    const { selectedCategoryDisabled } = this.state;
    return (
      <>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <h1>Find Work On Gigoshi</h1>
          <p>Browse and propose on jobs by selecting a category </p>
        </div>
        <Container id="page-container">
          <Container>
            <Input
              style={{ width: '-webkit-fill-available' }}
              type="select"
              required
              name="category"
              value={this.state.selectedCategory}
              onChange={this.handleChange}>
              <option value="" disabled={selectedCategoryDisabled}>
                search by category...
              </option>
              <option value="Graphics & Design">Graphics & Design</option>
              <option value="Music & Audio">Music & Audio</option>
              <option value="Programming & Tech">Programming & Tech</option>
              <option value="Digital Marketing">Digital Marketing</option>
              <option value="Business">Business</option>
              <option value="Writing & Translation">
                Writing & Translation
              </option>
              <option value="Data">Data</option>
              <option value="Lifestyle">Lifestyle</option>
              <option value="Video & Animation">Video & Animation</option>
            </Input>
          </Container>
            <Container style={{ marginTop: '20px', marginLeft: '20px' }} className="job-grid">
              {this.renderJobs()}
            </Container>
        </Container>
      </>
    );
  }
}

export default withRouter(JobSearchPage);
