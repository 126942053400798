/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './pageStyles.css';

export default class ProfilePage extends Component {
  render() {
    return (
      <div id="page-container">
        <h1>Profile: {this.props.match.params.username}</h1>
      </div>
    );
  }
}
