/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Auth } from 'aws-amplify';

import {
  Button,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Avatar from './Avatar';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';

export default class NavigationBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });

  signOut = async () => {
    try {
      await Auth.signOut();
      window.location.replace('/');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  dropdown = () => {
    const { username, userId, unauthenticated } = this.props;

    if (unauthenticated) {
      return (
        <Nav style={{ marginLeft: 'auto' }} navbar>
          <NavItem style={{ marginRight: '20px' }}>
            <Button style={{ height: '42px', backgroundColor: '#3849E3' }}>
              <Link to="/sign-in" style={{ ...linkStyle, color: '#FFF' }}>
                Sign In / Sign Up
              </Link>
            </Button>
          </NavItem>
        </Nav>
      );
    }

    const { dropdownOpen } = this.state;
    return (
      <Nav>
        <NavItem>
          <Button
            style={{
              height: '41.28px',
              backgroundColor: '#3849E3',
              marginRight: '5px',
            }}>
            <Link to="/jobs/create" style={{ ...linkStyle, color: '#FFF' }}>
              Post a Job
            </Link>
          </Button>
        </NavItem>
        <NavItem style={{ marginRight: '20px' }}>
          <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
            <DropdownToggle caret style={{ backgroundColor: '#3849E3' }}>
              <Avatar userId={userId} />
              <span style={{ marginLeft: '3px' }}>{username}</span>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={this.signOut}>Sign Out</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavItem>
      </Nav>
    );
  };

  render() {
    const { unauthenticated, hide, isAdmin } = this.props;

    if (hide) return null;

    return (
      <div>
        <Navbar color="faded" light expand="md" style={{ padding: '10px 0' }}>
          <NavbarBrand style={{ fontFamily: 'Marko One' }}>
            <Link to="/" style={{ textDecoration: 'none', color: '#3849E3' }}>
              <img src={logo} style={{ margin: '0 5%' }} />
              <sup style={{ color: '#838385' }}>BETA</sup>
            </Link>
          </NavbarBrand>
          {!unauthenticated && (
            <Nav
              className="mr-auto"
              navbar
              style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <NavItem>
                <NavLink href="#">
                  <Link to="/jobs/search" style={linkStyle}>
                    Search Jobs
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#">
                  <Link to="/jobs" style={linkStyle}>
                    My Jobs
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#">
                  <Link to="/proposals" style={linkStyle}>
                    My Proposals
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#">
                  <Link to="/contact" style={linkStyle}>
                    Contact Us
                  </Link>
                </NavLink>
              </NavItem>
              {isAdmin && (
                <NavItem>
                  <NavLink href="#">
                    <Link to="/admin" style={linkStyle}>
                      Admin
                    </Link>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          )}
          {this.dropdown()}
        </Navbar>
      </div>
    );
  }
}

const linkStyle = {
  textDecoration: 'none',
  color: 'black',
};
