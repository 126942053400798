/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateByChannelID = /* GraphQL */ `
  subscription OnCreateByChannelID($channelID: ID!) {
    onCreateByChannelID(channelID: $channelID) {
      id
      channelID
      author
      body
      read
      createdAt
      updatedAt
    }
  }
`;
export const onNewConversationForUser = /* GraphQL */ `
  subscription OnNewConversationForUser($userID: String!) {
    onNewConversationForUser(userID: $userID) {
      id
      channelID
      userID
      otherUserID
      username
      otherUsername
      jobTitle
      isAccepted
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConversationForUser = /* GraphQL */ `
  subscription OnDeleteConversationForUser($userID: String!) {
    onDeleteConversationForUser(userID: $userID) {
      id
      channelID
      userID
      otherUserID
      username
      otherUsername
      jobTitle
      isAccepted
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConversationByChannelID = /* GraphQL */ `
  subscription OnUpdateConversationByChannelID($channelID: ID!) {
    onUpdateConversationByChannelID(channelID: $channelID) {
      id
      channelID
      userID
      otherUserID
      username
      otherUsername
      jobTitle
      isAccepted
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      channelID
      author
      body
      read
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      channelID
      author
      body
      read
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      channelID
      author
      body
      read
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConversation = /* GraphQL */ `
  subscription OnCreateConversation {
    onCreateConversation {
      id
      channelID
      userID
      otherUserID
      username
      otherUsername
      jobTitle
      isAccepted
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConversation = /* GraphQL */ `
  subscription OnUpdateConversation {
    onUpdateConversation {
      id
      channelID
      userID
      otherUserID
      username
      otherUsername
      jobTitle
      isAccepted
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConversation = /* GraphQL */ `
  subscription OnDeleteConversation {
    onDeleteConversation {
      id
      channelID
      userID
      otherUserID
      username
      otherUsername
      jobTitle
      isAccepted
      createdAt
      updatedAt
    }
  }
`;
