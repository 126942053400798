import React, { Component } from 'react';
import { FaFileImage } from 'react-icons/fa';
import { Button, Container } from 'reactstrap';
import Loading from '../Loading';

export default class AdminViewProposal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transaction: null,
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({ transaction: this.props.location.state.state.transaction });
    }
  }

  renderAttachedFiles = () => {
    const files = this.state.transaction.attachedFiles;
    if (!files.length || files === null) return;
    return files.map((file) => {
      return (
        <div
          key={file.name}
          id="file-upload-block"
          onClick={() => this.downloadFile(file)}
          style={{
            fontSize: 12,
            textAlign: 'center',
            border: '1px solid #D1D1D1',
            borderRadius: 5,
            padding: 2,
            margin: 4,
            float: 'left',
            cursor: 'pointer',
          }}>
          <p>{file.name}</p>
          <FaFileImage
            id="file-upload-icon"
            style={{ fontSize: 100, padding: '5px 5px 10px 5px' }}
          />
        </div>
      );
    });
  };

  downloadFile = async (file) => {
    console.log(file);
    const response = await Storage.get(file.location, { download: true });
    console.log(response);

    const url = URL.createObjectURL(response.Body);
    const link = document.createElement('a');
    link.href = url;
    link.download = file.name || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        link.removeEventListener('click', clickHandler);
      }, 150);
    };
    link.addEventListener('click', clickHandler, false);
    link.click();
    return link;
  };

  goBack = () => {
      this.props.history.push('/admin');
  };

  render() {
    const { transaction } = this.state;

    if (!transaction) return <Loading />;

    const {
      budget,
      createdTs,
      currency,
      deadline,
      details,
      freelancerUsername,
      clientUsername,
      jobTitle,
      attachedFiles,
      walletId,
      transactionId,
    } = transaction;

    const transactionInfoAvailable = transactionId || walletId ? true : false;

    const filesAvailable =
      attachedFiles && attachedFiles !== null && attachedFiles.length
        ? true
        : false;

    return (
      <Container id="page-container" style={{ margin: 20, display: 'flex' }}>
        <div id="admin-view-proposal">
          <div id="proposal-details">
            <h3>Proposal Details</h3>
            <p>
              <b>Proposal For: </b> {jobTitle}
            </p>
            <p>
              <b>Job Client: </b> {clientUsername}
            </p>
            <p>
              <b>Proposal Freelancer: </b> {freelancerUsername}
            </p>
            <p>
              <b>Submited date: </b> {new Date(createdTs).toLocaleString()}
            </p>
            <p>
              <b>Proposed Cost: </b> {currency} {budget}
            </p>
            <p>
              <b>Proposed Deadline: </b> {deadline}
            </p>
            <p>
              <b>Details: </b>{' '}
              {details === null || !details ? 'None.' : details}
            </p>
            {filesAvailable && (
              <div
                id="attached-files"
                style={{ display: 'inline-flex', marginBottom: 5 }}>
                {(
                  <p>
                    <b>Attached Files: </b> (Click to download){' '}
                  </p>
                ) && this.renderAttachedFiles()}
              </div>
            )}
          </div>
          {transactionInfoAvailable && (
            <div id="transaction-info" style={{ marginTop: 10 }}>
              <h3>Transaction Details</h3>
              <p>
                <b>Transaction ID: </b>
                {transactionId}{' '}
              </p>
              {walletId && (
                <p>
                  <b>Wallet ID: </b>
                  {walletId}{' '}
                </p>
              )}
            </div>
          )}
        </div>

        <div id="back-btn" style={{ margin: '0px auto' }}>
          <Button color="primary" onClick={this.goBack}>
            Go Back
          </Button>
        </div>
      </Container>
    );
  }
}
