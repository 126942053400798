/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import config from '../config';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { Jumbotron, Button } from 'reactstrap';
import Loading from '../components/Loading';

const { PRODUCTDB_API_URL } = config;
import './pageStyles.css';
import Avatar from '../components/Avatar';

export default class MyProposalsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Proposals: null,
    };
  }

  componentDidMount = async () => {
    const user = await Auth.currentUserInfo();
    const { sub: freelancerId, username: freelancerUsername } = user.attributes;

    const res = await axios.get(
      `${PRODUCTDB_API_URL}/freelancers/${freelancerId}/proposals`,
    );
    const { Proposals } = res.data;

    this.setState({
      Proposals,
      freelancerUsername,
    });
  };

  proposalStatus = (proposal) => {
    console.log(proposal.state);
    const { isAccepted, isJobCompleted, isClosed, state } = proposal;

    if (isJobCompleted || state === 'completed') return <p>Completed</p>;

    if (isClosed) return <p>Closed - (Other Proposal selected)</p>;

    if (isAccepted && state === 'verifyingPayment')
      return <p>Verifying Payment</p>;
    else if (
      isAccepted &&
      (state === 'verifyingPayment' || state === 'invalidPayment')
    )
      return <p>In Progress</p>;
    else if (
      isAccepted &&
      (state === 'releasingFunds' || state === 'invalidPayment')
    )
      return <p>Releasing Funds</p>;

    return <p>Open</p>;
  };

  renderProposals = () => {
    const { Proposals } = this.state;

    if (!Proposals) return <Loading />;

    if (!Proposals.length)
      return <p style={{ textAlign: 'center' }}>No Proposals Yet</p>;

    // TODO: make component reusable across this and MyJobs
    return Proposals.map((proposal) => {
      const {
        jobTitle,
        budget,
        currency,
        deadline,
        createdTs,
        details,
        jobKey,
        state,
      } = proposal;

      const clientId = jobKey.split('#'[1]);

      const proposalKey = `${proposal.createdTs}#${proposal.freelancerId}`;
      const redirectUri = `/proposals/${encodeURIComponent(proposalKey)}`;

      return (
        <div
          key={createdTs}
          style={{ backgroundColor: '#eee', margin: '20px' }}>
          <Jumbotron key={createdTs} style={{ margin: '20px' }}>
            <h1 className="display-3">{jobTitle}</h1>
            <p className="lead">
              {details || (
                <i>
                  No details provided... (Descriptions will help Clients find
                  your proposal more attractive)
                </i>
              )}
            </p>
            <hr className="my-2" />
            <Avatar userId={clientId} backgroundColor="#FFF" />
            <b style={{ marginLeft: '5px' }}>{`${budget} ${currency}`}</b>-
            <span style={{ fontStyle: 'italic' }}>
              {' '}
              <b>Posted: </b>{' '}
              {new Date(createdTs).toLocaleString('en-US', {
                month: 'long',
                day: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
              })}{' '}
              , <b> Due: </b>{' '}
              {new Date(deadline).toLocaleString('en-US', {
                month: 'long',
                day: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
              })}
            </span>
            {this.proposalStatus(proposal)}
            <p className="lead">
              <Button
                onClick={() => this.props.history.push(redirectUri)}
                color={state === 'completed' ? 'success' : 'primary'}
                style={{
                  marginBottom: '10px',
                  marginLeft: '90%',
                }}>
                View Proposal
              </Button>
            </p>
          </Jumbotron>
        </div>
      );
    });
  };

  render() {
    return (
      <div id="page-container">
        <h1 style={{ textAlign: 'center' }}>My Proposals</h1>
        <div>{this.renderProposals()}</div>
      </div>
    );
  }
}
