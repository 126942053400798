import React, { Component } from 'react';
import './pageStyles.css';

export default class NotFoundPage extends Component {
  render() {
    return (
      <div id="page-container">
        <h1>Oops page not found</h1>
      </div>
    );
  }
}
