/* eslint-disable react/prop-types */
import React, { Component, createContext } from 'react';

const JobsContext = createContext();
const JobsProvider = JobsContext.Provider;
// const FreelancersContext = createContext()
// const FreelancersProvider = FreelancersContext.Provider

class Context extends Component {
  render() {
    return (
      <JobsProvider value={'Jobs Data'}>{this.props.children}</JobsProvider>
    );
  }
}

export { JobsContext, Context };
