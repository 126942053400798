/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import axios from 'axios';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import config from '../config';
import { Auth } from 'aws-amplify';
import Loading from '../components/Loading';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Storage } from 'aws-amplify';
import { FaFileImage } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { MixpanelContext } from '../MixpanelContext';

const { PRODUCTDB_API_URL } = config;

class JobView extends Component {
  static contextType = MixpanelContext;

  constructor(props) {
    super(props);

    this.state = {
      isEditing: null,
      isMyJobPost: null,
      deleteAlertOpen: false,
      Job: null,
      updatedFields: null,
    };
  }

  componentDidMount = async () => {
    const user = await Auth.currentUserInfo();
    const currentUserId = user.attributes.sub;
    const { jobKey } = this.props.match.params;
    const [jobTs, clientId] = decodeURIComponent(jobKey).split('#');
    const isMyJobPost = currentUserId === clientId;
    const res = await axios.get(
      `${PRODUCTDB_API_URL}/clients/${clientId}/jobs/${jobTs}`,
    );
    const { Job } = res.data;

    this.setState({
      isMyJobPost,
      jobTs,
      updatedFields: { ...Job },
      Job,
      username: user.username,
    });

    const mixpanel = this.context;
    if (mixpanel) {
      this.jobViewMixpanelTrigger();
    }
  };

  jobViewMixpanelTrigger = () => {
    const mixpanel = this.context;
    mixpanel.track('Job Posting Viewed', {
      source: 'Gigoshi Job View Page',
    });
  };

  editBtn = () => {
    this.setState({ isEditing: true });
  };

  deleteBtn = () => {
    this.setState({ deleteAlertOpen: true });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const { updatedFields } = this.state;
    updatedFields[name] = value;

    this.setState({
      updatedFields,
    });
  };

  updateJob = async () => {
    const { updatedFields } = this.state;
    const { clientId, createdTs } = this.state.Job;

    let updateJobData = {};
    const fields = Object.keys(updatedFields);
    for (let field of fields) {
      if (updatedFields[field] !== this.state.Job[field]) {
        updateJobData[field] = updatedFields[field];
      }
    }

    try {
      const res = await axios.put(
        PRODUCTDB_API_URL +
        `/clients/${clientId}/jobs/${encodeURIComponent(createdTs)}`,
        updateJobData,
      );
      const updatedJob = res.data;
      console.log('updatedJob');
      console.log(updatedJob);

      this.setState({
        isEditing: false,
        Job: updatedJob,
      });
    } catch (error) {
      console.log('Couldnt update job');
      console.log(error);
    }
  };

  onDeleteClick = async () => {
    const { Job } = this.state;
    const { clientId, createdTs } = Job;

    try {
      await axios.delete(
        PRODUCTDB_API_URL + `/clients/${clientId}/jobs/${createdTs}`,
      );

      // redirect to my jobs
      this.props.history.push(`/jobs`);
    } catch (error) {
      console.log('Couldnt delete job');
      console.log(error);
    }
  };

  renderAttachedFiles = () => {
    const images = this.state.Job.attachedImages;
    if (!images.length) return;
    return images.map((image) => {
      return (
        <div
          key={image.name}
          id="file-upload-block"
          onClick={() => this.downloadFile(image)}
          style={{
            fontSize: 12,
            textAlign: 'center',
            border: '1px solid #D1D1D1',
            borderRadius: 5,
            padding: 2,
            margin: 4,
            float: 'left',
            cursor: 'pointer',
          }}>
          <p>{image.name}</p>
          <FaFileImage
            id="file-upload-icon"
            style={{ fontSize: 100, padding: '5px 5px 10px 5px' }}
          />
        </div>
      );
    });
  };

  downloadFile = async (file) => {
    console.log(file);
    const response = await Storage.get(file.location, { download: true });
    console.log(response);

    const url = URL.createObjectURL(response.Body);
    const link = document.createElement('a');
    link.href = url;
    link.download = file.name || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        link.removeEventListener('click', clickHandler);
      }, 150);
    };
    link.addEventListener('click', clickHandler, false);
    link.click();
    return link;
  };

  setAttachments = (s3UploadData) => {
    let filesMetaData = [];
    for (let i = 0; i < s3UploadData.length; i++) {
      filesMetaData.push({
        name: s3UploadData[i].file.name,
        location: s3UploadData[i].location,
      });
    }
    this.setState({
      attachedImages: filesMetaData,
      s3UploadData: s3UploadData,
    });
  };

  deleteAlert = () => {
    return (
      <div className="delete-alert">
        <Alert
          className="proposal-delete-alert"
          isOpen={this.state.deleteAlertOpen}
          color="warning">
          <div className="proposal-delete-alert-btns">
            Are you sure?
            <Button onClick={() => this.onDeleteClick()} color="danger">
              Yes
            </Button>
            <Button onClick={() => this.setState({ deleteAlertOpen: false })}>
              No
            </Button>
          </div>
        </Alert>
      </div>
    );
  };

  proposalLinks = () => {
    const { isMyJobPost, Job } = this.state;
    const { isJobCompleted, createdTs, acceptedProposalId } = Job;
    const { jobKey } = this.props.match.params;

    if (isJobCompleted) {
      return 'Job Completed';
    }

    if (acceptedProposalId) {
      return (
        <Link to={`/proposals/${encodeURIComponent(acceptedProposalId)}`}>
          <Button style={{ backgroundColor: '#3849E3' }}>
            View Accepted Proposal
          </Button>
        </Link>
      );
    }

    if (isMyJobPost) {
      return (
        <Link to={`/jobs/${encodeURIComponent(createdTs)}/proposals`}>
          <Button style={{ backgroundColor: '#3849E3' }}>View Proposals</Button>
        </Link>
      );
    }

    return <Link to={`/jobs/${jobKey}/proposals/create`}>Create Proposal</Link>;
  };

  render() {
    const { isEditing, isMyJobPost, updatedFields, Job } = this.state;

    if (!Job) return <Loading />;

    const {
      title,
      description,
      attachedImages,
      budgetMax,
      currency,
      category,
      createdTs,
      acceptedProposalId,
      deadline
    } = Job;

    const filesAvailable =
      attachedImages && attachedImages !== null && attachedImages.length
        ? true
        : false;
    console.log(filesAvailable);

    // save should be disabled if no changes are made, or right after an update
    // need to make sure the new entered values are not the same as the original values and also that they are not null
    const shouldDisableSave =
      title === updatedFields.title &&
      category === updatedFields.category &&
      description === updatedFields.description &&
      budgetMax === updatedFields.budgetMax;

    if (isEditing) {
      return (
        <Container id="page-container">
          <Form>
            <FormGroup>
              <Label for="jobTitle">Title: </Label>
              <Input
                name="title"
                type="text"
                id="jobTitle"
                defaultValue={title}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="jobCategory">Category: </Label>
              <Input
                type="select"
                required
                name="category"
                id="jobCategory"
                defaultValue={category}
                onChange={this.handleInputChange}>
                <option value="default" disabled>
                  select category...
                </option>
                <option value="Graphics & Design">Graphics & Design</option>
                <option value="Music & Audio">Music & Audio</option>
                <option value="Programming & Tech">Programming & Tech</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="Business">Business</option>
                <option value="Writing & Translation">
                  Writing & Translation
                </option>
                <option value="Data">Data</option>
                <option value="Lifestyle">Lifestyle</option>
                <option value="Video & Animation">Video & Animation</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="jobDescription">Description: </Label>
              <Input
                type="textarea"
                name="description"
                id="jobDescription"
                defaultValue={description}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label>Budget: </Label>
              <Col>
                <Input
                  name="budgetMax"
                  type="text"
                  placeholder="budget maximum"
                  defaultValue={budgetMax}
                  onChange={this.handleInputChange}
                />
              </Col>
              <Col sm={3}>
                <Input
                  type="text"
                  required
                  name="currency"
                  disabled
                  defaultValue={'USDT'}
                  onChange={this.handleInputChange}
                />
              </Col>
            </FormGroup>
            {/* <UploadFile files={this.state.Job.attachedImages} setAttachments={this.setAttachments} /> */}
          </Form>
          <div style={{ margin: '10px 0' }}>
            <Button onClick={() => this.setState({ isEditing: false })}>
              Back
            </Button>
            <Button
              style={{ float: 'right' }}
              color="primary"
              onClick={this.updateJob}
              disabled={shouldDisableSave}>
              Save
            </Button>
          </div>
        </Container>
      );
    }

    return (
      <Container id="page-container">
        {this.deleteAlert()}
        <h2>
          {title}
          {isMyJobPost && !acceptedProposalId && (
            <span style={{ float: 'right' }}>
              <BsPencil
                size="20px"
                onClick={this.editBtn}
                cursor="pointer"
                color="blue"
              />

              <BsTrash
                size="20px"
                style={{ marginLeft: '10px' }}
                onClick={this.deleteBtn}
                cursor="pointer"
                color="red"
              />
            </span>
          )}
        </h2>
        <p>
          <b>Job Category:</b> {category}
        </p>
        <p>
          <b>Post date:</b>{' '}
          {new Date(createdTs).toLocaleString('en-US', {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          })}
        </p>
        <p>
          <b>Due date:</b>{' '}
          {new Date(deadline).toLocaleString('en-US', {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          })}
        </p>
        <p>
          <b>Description: </b>
          {description}
        </p>
        <p>
          <b>Budget: </b> {budgetMax} {currency}
        </p>
        <div style={{ display: 'flex', marginBottom: 5 }}>
          {filesAvailable
            ? <p>Attached Files: (Click to download) </p> &&
            this.renderAttachedFiles()
            : 'No files available'}
        </div>
        {this.proposalLinks()}
      </Container>
    );
  }
}

export default withRouter(JobView);
