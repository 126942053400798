import React, { Component } from 'react';
import QRCode from "react-qr-code";
import { Form, Label, Input, FormGroup, Col, Container, Row } from 'reactstrap';
import config from '../config';
import axios from 'axios';

const { GIGOSHI_WALLET_ADDR, PRODUCTDB_API_URL } = config;


export default class InputtransactionId extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitLoading: false
        };
    }


    handleInputChange = (event) => {
        const { value, name } = event.target;

        this.setState(
            {
                [name]: value,
            },
        );
    };

    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({ submitLoading: true });
        await this.addTransactionId();
        this.setState({ submitLoading: false });
        window.location.reload();
    }

    addTransactionId = async () => {
        const { createdTs: proposalTs, freelancerId, jobKey } = this.props.proposal;
        const { transactionId } = this.state;

        const [jobTs, clientId] = jobKey.split('#');
        await axios.put(`${PRODUCTDB_API_URL}/clients/${clientId}/jobs/${encodeURIComponent(jobTs)}?transactionId=${transactionId}&proposalTs=${encodeURIComponent(proposalTs)}&freelancerId=${freelancerId}`);
    }

    render() {
        const { transactionId, submitLoading } = this.state;
        const { proposal } = this.props;
        const proposalKey = `${proposal.createdTs}#${proposal.freelancerId}`;
        const qrUri = `https://gigoshi.net/proposals/${encodeURIComponent(proposalKey)}/wallet`;
        return (
            <Container style={{ margin: "5vh" }}>
                <Row>
                    <Col sm="5">
                        <Container style={{ textAlign: 'center', borderRight: '1px solid #333' }}>
                            <h3>Mobile </h3>
                            <p>Scan QR code to make payment</p>
                            <QRCode value={qrUri} />
                        </Container>
                    </Col>

                    <Col sm="7">
                        <Container>
                            <h3 style={{ textAlign: 'center' }}>Desktop </h3>
                            <Container>
                                <h5 style={{ marginTop: '30px' }}>Enter this address in your wallet</h5>
                                <p>{GIGOSHI_WALLET_ADDR}</p>
                            </Container>
                            <Form onSubmit={this.handleSubmit} style={{ marginLeft: "15px", marginTop: "50px" }}>
                                <FormGroup className="form-field" style={{ margin: 10 }}>
                                    <Label for="transactionId" style={{ fontSize: "15px" }}>In order to begin this job, our team must validate your payment - Enter the Transaction ID here:</Label>
                                    <Input
                                        onChange={this.handleInputChange}
                                        required
                                        name="transactionId"
                                        type="text"
                                        id="transactionId"
                                    />
                                </FormGroup>
                                <FormGroup className="form-field" style={{ margin: 10 }}>
                                    <Input className="form-submit-btn" type="submit" disabled={!transactionId || submitLoading} />
                                </FormGroup>
                            </Form>
                        </Container>
                    </Col>
                </Row>
            </Container>
        );
    }
}
