/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import ClientJobProposals from '../components/ClientJobProposals';
import './pageStyles.css';

export default class ClientProposalsPage extends Component {
  render() {
    console.log('this.props');
    console.log(this.props);
    return (
      <div id="page-container">
        <ClientJobProposals jobTs={this.props.match.params.createdTs} />
      </div>
    );
  }
}
