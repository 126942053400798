/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import axios from 'axios';
import config from '../config';
import { Auth } from 'aws-amplify';
import DatePicker from 'reactstrap-date-picker';
import { Redirect } from 'react-router-dom';
import {
  Form,
  Label,
  Input,
  FormGroup,
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  ButtonToolbar,
} from 'reactstrap';

const { PRODUCTDB_API_URL } = config;

export default class UpdateProposal extends Component {
  constructor(props) {
    super(props);
  }

  handleSubmit = () => {
    this.props.handleSubmit(event);
  };

  render() {
    const {
      budget,
      createdTs,
      currency,
      deadline,
      details,
      freelancerUsername,
      clientUsername,
      isAccepted,
      isJobCompleted,
      jobTitle,
      attachedImages,
    } = this.props.updatedFields;
    const submitDisabled = !budget || !deadline;

    return (
      <Container className="update-proposal-container">
        <h1 style={{ margin: 5 }}>
          Update Proposal{' '}
          <div style={{ float: 'right', margin: 5 }}>
            <Button size="sm" onClick={this.props.toggleEditing}>
              Back
            </Button>
            <Button size="sm" disabled={submitDisabled} onClick={this.handleSubmit}>
              Save
            </Button>
          </div>
        </h1>

        <Form onSubmit={this.props.handleSubmit}>
          <FormGroup className="form-field" style={{ margin: 10 }}>
            <Label for="jobTitle">Job Title:</Label>
            <Input name="title" type="text" id="jobTitle" defaultValue={jobTitle} disabled />
          </FormGroup>
          <FormGroup className="form-field" style={{ margin: 10 }}>
            <Label for="jobClient">Client: </Label>
            <Input name="client-name" type="text" id="clientName" defaultValue={clientUsername} disabled />
          </FormGroup>
          <FormGroup className="form-field" style={{ margin: 10 }}>
            <Label for="freelance-username">Freelancer Username: </Label>
            <Input
              defaultValue={freelancerUsername}
              disabled
              name="freelancerUsername"
              type="text"
              id="freelanceUsername"
            />
          </FormGroup>
          <FormGroup className="form-field" style={{ margin: 10 }}>
            <Label for="budget">Budget: </Label>
            <InputGroup>
              <Input
                onChange={this.props.handleInputChange}
                name="budget"
                type="text"
                id="freelanceBudget"
                value={budget}
                required
              />
              <InputGroupAddon addonType="append" style={{ display: 'flex' }}>
                <InputGroupText>USDT</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <FormGroup className="form-field" style={{ margin: 10 }}>
            <Label for="currency">Currency: </Label>
            <Input disabled type="text" name="currency" value={"USDT"}/>
          </FormGroup>
          <FormGroup className="form-field" style={{ margin: 10 }}>
            <Label for="deadline">Deadline: </Label>
            <DatePicker
              instanceCount={3}
              required
              id="freelance-deadline-datepicker"
              value={deadline}
              onChange={(value) => this.props.handleDateChange(value)}
              previousButtonElement="Prev"
              nextButtonElement="Next"
              clearButtonElement="Clear"
              showTodayButton={true}
            />
          </FormGroup>
          <FormGroup className="form-field" style={{ margin: 10 }}>
            <Label for="details">Other details ({500 - (details?.length || 0)})</Label>
            <Input
              type="textarea"
              name="details"
              id="details"
              rows="4"
              maxLength="500"
              value={details}
              onChange={this.props.handleInputChange}
            />
          </FormGroup>
        </Form>
      </Container>
    );
  }
}
