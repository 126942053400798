import React, { Component } from 'react';
import { Button, Jumbotron } from 'reactstrap';
import Avatar from '../Avatar';
import { BsFlagFill } from 'react-icons/bs';

export default class ReleaseFundsCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { transaction, proposalKey, windowWidth } = this.props;
    const {
      createdTs,
      freelancerUsername,
      walletId,
      budget,
      jobKey,
      freelancerId,
      state,
      payoutErrorNote,
    } = transaction;

    let shortenedWalletId = walletId;

    if (windowWidth >= 1000 && windowWidth < 1200) {
      shortenedWalletId = shortenedWalletId.substring(0, 40) + '...';
    } else if (windowWidth < 1000) {
      shortenedWalletId = shortenedWalletId.substring(0, 30) + '...';
    }

    return (
      <div
        key={createdTs}
        style={{
          backgroundColor: '#eee',
          margin: '50px auto',
          borderRadius: 10,
          width: '85%',
        }}>
        <Jumbotron
          key={createdTs}
          style={{ margin: '20px', padding: '10px 5px' }}>
          <div id="transaction-info">
            <span>
              <h5>
                Freelancer: {freelancerUsername}{' '}
                <Avatar userId={freelancerId} />
                {transaction.state === 'payoutError' && (
                  <BsFlagFill size="20px" color="red" />
                )}
              </h5>
            </span>
            <h5>Release Amount: ${budget}</h5>
            <h5>Freelancer Wallet ID: {shortenedWalletId}</h5>
            {state === 'payoutError' && <h5>Error Note: {payoutErrorNote}</h5>}
            <div>
              <Button
                color="primary"
                style={{ marginRight: 10 }}
                onClick={() =>
                  this.props.viewProposalClick(proposalKey, transaction)
                }>
                <b>View Proposal</b>
              </Button>
              <Button
                color="primary"
                style={{ marginRight: 10 }}
                onClick={() => this.props.viewJobClick(jobKey)}>
                <b>View Job</b>
              </Button>
              <span style={{ float: 'right' }}>
                <Button
                  style={{ marginRight: 10 }}
                  color="success"
                  onClick={() => this.props.acceptFundsRelease(transaction)}>
                  <b>Accept</b>
                </Button>
                {state !== 'payoutError' && (
                  <Button
                    color="danger"
                    onClick={() => this.props.rejectFundsRelease(transaction)}>
                    <b>Reject</b>
                  </Button>
                )}
              </span>
            </div>
          </div>
        </Jumbotron>
      </div>
    );
  }
}
