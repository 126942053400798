/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Container, Row, Col, Card, CardTitle, CardBody } from 'reactstrap';
import nft_img from '../images/NFT_Token Development Icon .png';
import market_img from '../images/digital marketing.png';
import graphic_img from '../images/Graphic Design.png';
import video_img from '../images/Video.png';
import music_img from '../images/Music.png';
import tech_img from '../images/Programming _ Tech.png';

const styles = {
  card: {
    padding: '5%',
    borderRadius: 20,
    margin: '10% auto',
    boxShadow: '5px 5px 10px rgb(0 0 0 / 20%)',
    border: 'none',
    height: '70%',
    width: '85%',
    minWidth: "245px",
    minHeight: "162px",
  },
  container: {
    marginTop: '10%',
  },
  title: {
    textAlign: 'center',
    color: '#3849E3',
    fontWeight: 800,
  },
  img: {
    width: '15%',
    marginRight: '2%',
  },
  cardTitle: {
    color: '#3849E3'
  }
};

export default class PopularServices extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Container id="popular-services" style={styles.container}>
        <h3 style={styles.title}>Popular Services</h3>
        <Row>
          <Col>
            <Card id="nft-dev" style={styles.card}>
              <CardTitle style={styles.cardTitle}>
                <h5>
                  <img src={nft_img} style={styles.img} />
                  NFT/Token Devlopment
                </h5>
              </CardTitle>
              <CardBody>
                Find talent across all blockchains to create, develop, and
                launch your projects.
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card style={styles.card}>
              <CardTitle style={styles.cardTitle}>
                <h5>
                  <img src={graphic_img} style={styles.img} />
                  {'Graphics & Design'}
                </h5>
              </CardTitle>
              <CardBody>
                Visual content for you or your business. From logo designs to
                flyer creation.
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card style={styles.card}>
              <CardTitle style={styles.cardTitle}>
                <h5>
                  <img src={tech_img} style={styles.img} />
                  {'Programming & Tech'}
                </h5>
              </CardTitle>
              <CardBody>
                All your tech needs, including web and mobile development,
                support and IT, and code review.
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={styles.card}>
              <CardTitle style={styles.cardTitle}>
                <h5>
                  <img src={music_img} style={styles.img} />
                  {'Music & Audio'}
                </h5>
              </CardTitle>
              <CardBody>
                Full cycle help, from production to final edits. Includes voice
                overs, mixing, and effects.
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card style={styles.card}>
              <CardTitle style={styles.cardTitle}>
                <h5>
                  <img src={video_img} style={styles.img} />
                  {'Video & Animation'}
                </h5>
              </CardTitle>
              <CardBody>
                Create custom content that includes effects, image animation,
                and editing.
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card style={styles.card}>
              <CardTitle style={styles.cardTitle}>
                <h5>
                  <img src={market_img} style={styles.img} />
                  Digital Marketing
                </h5>
              </CardTitle>
              <CardBody>
                Find talent that will help promote your brand to reach potential
                customers and clients.
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}