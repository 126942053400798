import React, { Component } from 'react';
import { Container } from 'reactstrap';
import '../pageStyles.css';
import config from '../../config';
import axios from 'axios';
import PaymentErrorCard from '../../components/admin-ui/PaymentErrorCard';

const { ADMIN_API_URL } = config;

export default class PaymentErrorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transactions: [],
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount = async () => {
    //fetch all transactions that need to be verified
    const response = await axios.get(`${ADMIN_API_URL}/admin/proposals`, {
      params: { filter: 'error' },
    });
    const proposals = response.data.Proposals;
    this.setState({ transactions: proposals });
    window.addEventListener('resize', this.handleWindowResize);
  };

  handleWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  viewProposalClick = (proposalKey, transaction) => {
    const redirectUri = `/admin/proposal/${encodeURIComponent(proposalKey)}`;
    return this.props.history.push(redirectUri, {
      state: { transaction, page: 'paymentErrors' },
    });
  };

  resolvePaymentError = async (transaction) => {
    const {
      freelancerId,
      createdTs,
      state,
      budget,
      clientUsername,
      freelancerUsername,
    } = transaction;

    let windowMessage;

    if (state === 'invalidPayment') {
      windowMessage = `Are you sure this payment of $${budget} from ${clientUsername} has been resolved?`;
    } else if (state === 'payoutError') {
      windowMessage = `Are you sure this payment of $${budget} to ${freelancerUsername} has been resolved?`;
    }

    if (window.confirm(windowMessage)) {
      // Make api call to update state to in progress
      try {
        const body = { accepted: true, errorNote: '' };
        await axios.put(
          `${ADMIN_API_URL}/admin/freelancers/${freelancerId}/proposals/${createdTs}?currentState=${state}`,
          body,
        );

        let proposals = this.state.transactions;
        proposals = proposals.filter((p) => p !== transaction);
        this.setState({ transactions: proposals });
      } catch (error) {
        console.log(
          `Could not update proposal state from ${state} after trying to resolve payment.`,
        );
        console.log(error);
      }
    }
  };

  viewJobClick = (jobKey) => {
    const jobUri = `/jobs/${encodeURIComponent(jobKey)}`;
    this.props.history.push(jobUri);
  };

  renderCards = () => {
    const { transactions, windowWidth } = this.state;

    return transactions.map((transaction) => {
      const { createdTs, freelancerId } = transaction;
      const proposalKey = `${createdTs}#${freelancerId}`;

      return (
        <PaymentErrorCard
          transaction={transaction}
          key={createdTs}
          proposalKey={proposalKey}
          viewProposalClick={this.viewProposalClick}
          viewJobClick={this.viewJobClick}
          resolvePaymentError={this.resolvePaymentError}
          windowWidth={windowWidth}
        />
      );
    });
  };

  render() {
    const { transactions } = this.state;
    return (
      <Container id="page-container">
        {transactions && this.renderCards()}
      </Container>
    );
  }
}
