import React, { Component } from "react";
import "./pageStyles.css";

export default class SupportPage extends Component {
  render() {
    return (
      <div id="page-container" style={{ textAlign: "center" }}>
        <h1>Support</h1>
        <p>If you are having issues with the website or need to file a dispute please email <b>help@gigoshi.com</b></p>
      </div>
    );
  }
}
