/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Button, Jumbotron } from 'reactstrap';
import Avatar from '../Avatar';

export default class PaymentErrorCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  render() {
    const { transaction, proposalKey, windowWidth } = this.props;
    const {
      createdTs,
      clientUsername,
      freelancerUsername,
      transactionId,
      budget,
      currency,
      jobKey,
      clientId,
      walletId,
      state,
      payoutErrorNote,
      invalidPaymentNote,
    } = transaction;

    let shortenedTransactionId = transactionId;

    if (transactionId && windowWidth >= 1000 && windowWidth < 1200) {
      shortenedTransactionId = shortenedTransactionId.substring(0, 40) + '...';
    } else if (transactionId && windowWidth < 1000) {
      shortenedTransactionId = shortenedTransactionId.substring(0, 30) + '...';
    }

    return (
      <div
        key={createdTs}
        style={{
          backgroundColor: '#eee',
          margin: '50px auto',
          borderRadius: 10,
          width: '85%',
        }}>
        <Jumbotron
          key={createdTs}
          style={{ margin: '20px', display: 'inline-flex' }}>
          <div id="transaction-info">
            <span>
              {state === 'invalidPayment' && (
                <h5>
                  Client: {clientUsername} <Avatar userId={clientId} />
                </h5>
              )}
              {state === 'payoutError' && (
                <h5>
                  Freelancer: {freelancerUsername} <Avatar userId={clientId} />
                </h5>
              )}
            </span>
            <h5>
              Transaction Amount: {currency} {budget}
            </h5>
            {transactionId && <h5>Txn ID: {shortenedTransactionId}</h5>}
            {walletId && <h5>Freelancer Wallet ID: {walletId}</h5>}
            {state === 'invalidPayment' && (
              <h5>Error Note: {invalidPaymentNote}</h5>
            )}
            {state === 'payoutError' && <h5>Error Note: {payoutErrorNote}</h5>}
            <Button
              color="primary"
              style={{ marginRight: 10 }}
              onClick={() =>
                this.props.viewProposalClick(proposalKey, transaction)
              }>
              <b>View Proposal</b>
            </Button>
            <Button
              color="primary"
              style={{ marginRight: 10 }}
              onClick={() => this.props.viewJobClick(jobKey)}>
              <b>View Job</b>
            </Button>
            <Button
              color="success"
              onClick={() => this.props.resolvePaymentError(transaction)}>
              <b>Resolve Payment</b>
            </Button>
          </div>
        </Jumbotron>
      </div>
    );
  }
}
