/* eslint-disable react/prop-types */
import React, { Component } from "react";
import axios from 'axios';
import config from '../config';
import "./pageStyles.css";
import { Auth } from "aws-amplify";
import { Redirect, withRouter } from "react-router-dom";
import { Container, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import UploadFile from "../components/UploadFile";
import { Storage } from 'aws-amplify';
import { MixpanelContext } from '../MixpanelContext';
import DatePicker from 'reactstrap-date-picker';


const { PRODUCTDB_API_URL } = config;

class JobPostingPage extends Component {
  static contextType = MixpanelContext;

  constructor(props) {
    super(props);
    this.state = {
      clientUsername: null,
      title: null,
      category: null,
      description: null,
      attachedImages: [],
      budgetMax: null,
      currency: "USDT",
      submitted: null,
      submitLoading: false,
      s3UploadData: []
    };
  }

  componentDidMount = async () => {
    const { username: clientUsername } = await Auth.currentAuthenticatedUser();
    this.setState({ clientUsername });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  setAttachments = (s3UploadData) => {
    let filesMetaData = [];
    for (let i = 0; i < s3UploadData.length; i++) {
      filesMetaData.push({ name: s3UploadData[i].file.name, location: s3UploadData[i].location });
    }
    this.setState({ attachedImages: filesMetaData, s3UploadData: s3UploadData });
  };

  handleDateChange = (value) => {
    this.setState({ deadline: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ submitLoading: true });
    const {
      title,
      category,
      description,
      attachedImages,
      budgetMax,
      currency,
      clientUsername,
      s3UploadData,
      deadline
    } = this.state;

    const user = await Auth.currentUserInfo(); // TODO put this in context
    const clientId = user.attributes.sub;

    this.mixpanelFormSubmissionTrigger();

    const createJobData = {
      clientId,
      clientUsername,
      title,
      category,
      description,
      attachedImages,  // TODO implement 
      budgetMax,
      currency,
      deadline
    };

    for (let i = 0; i < s3UploadData.length; i++) {
      await Storage.put(s3UploadData[i].location, s3UploadData[i].file);
      console.log('uploading to S3 : ', s3UploadData[i]);
    }
    const createJobRes = await axios.post(PRODUCTDB_API_URL + `/clients/${clientId}/jobs`, createJobData);
    console.log('createJobRes');
    console.log(createJobRes);

    this.setState({
      title: null,
      category: null,
      description: null,
      attachedImages: [],
      budgetMax: null,
      currency: null,
      submitted: true,
      submitLoading: false,
      deadline: null
    });
  }

  mixpanelFormSubmissionTrigger = () => {
    const mixpanel = this.context;
    mixpanel.track("New Job Created", {
      client: this.state.clientUsername,
      job: this.state.title,
      category: this.state.category
    });
  }

  render() {
    const {
      title,
      category,
      description,
      budgetMax,
      submitted,
      submitLoading,
      currency,
      deadline
    } = this.state;

    if (submitted) {
      return <Redirect to="/jobs" />;
    }

    const shouldDisableSubmit = (!title || !category || !description || !budgetMax);

    return (
      <Container>
        <h1 style={{ textAlign: "center", marginTop: "20px" }}>Create Job {this.props.match.params.id}</h1>
        <Form onSubmit={this.handleSubmit}>
        <FormGroup style={{ marginTop: 10 }}>
            <Label for="jobTitle">Title: </Label>
            <Input name="title" type="text" id="jobTitle" onChange={this.handleInputChange} />
          </FormGroup>
          <FormGroup style={{ marginTop: 10 }}>
            <Label for="jobCategory">Category: </Label>
            <Input type="select" required name="category" id="jobCategory" defaultValue="default" onChange={this.handleInputChange}>
              <option value="default" disabled >select category...</option>
              <option value="Graphics & Design">Graphics & Design</option>
              <option value="Music & Audio">Music & Audio</option>
              <option value="Programming & Tech">Programming & Tech</option>
              <option value="Digital Marketing">Digital Marketing</option>
              <option value="Business">Business</option>
              <option value="Writing & Translation">Writing & Translation</option>
              <option value="Data">Data</option>
              <option value="Lifestyle">Lifestyle</option>
              <option value="Video & Animation">Video & Animation</option>
            </Input>
          </FormGroup>
          <FormGroup style={{ marginTop: 10 }}>
            <Label for="jobDescription">Description: </Label>
            <Input type="textarea" name="description" id="jobDescription" onChange={this.handleInputChange} />
          </FormGroup>
          <FormGroup style={{ marginTop: 10 }} row>
            <Label>Budget: Please make sure to pay in only BEP20/BNB tokens.
              <strong>
                {" Gigoshi is not responsible for any lost transactions."}
              </strong>
            </Label>
            <Col>
              <Input name="budgetMax" type="text" placeholder="budget maximum" onChange={this.handleInputChange} />
            </Col>
            <Col sm={3}>
              <Input type="text" disabled name="currency" value={currency} />
            </Col>
          </FormGroup>
          <FormGroup style={{ marginTop: 10 }}>
            <Label for="deadline">When would you like this job to be done by? </Label>
            <DatePicker
              instanceCount={3}
              required
              id="client-deadline-datepicker"
              value={deadline}
              onChange={(value) => this.handleDateChange(value)}
              previousButtonElement="Prev"
              nextButtonElement="Next"
              clearButtonElement="Clear"
              showTodayButton={true}
            />
          </FormGroup>
          <UploadFile setAttachments={this.setAttachments} />
          <Input
            type="submit"
            disabled={shouldDisableSubmit || submitLoading} />
        </Form>
      </Container>
    );
  }
}

export default withRouter(JobPostingPage);