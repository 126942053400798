import React, { Component } from 'react';
import './pageStyles.css';
import { Container, Jumbotron, Row, Col, Button } from 'reactstrap';
import { MixpanelContext } from '../MixpanelContext';
import img from '../images/placeholder.png';
import PopularServices from '../components/PopularServices';
import { Link } from 'react-router-dom';

const styles = {
  container: {
    marginTop: 30,
  },
  jumbotron: {
    margin: '5%',
    alignItems: 'center',
  },
  image: {
    maxWidth: '80%',
  },
  row: {
    alignItems: 'center',
  },
  getStarted: {
    padding: '2% 6%',
    backgroundColor: '#3849E3',
    color: 'white',
    borderRadius: 7,
    marginLeft: '15%',
    marginRight: '15%',
  },
  h1: {
    color: '#3849E3',
    fontWeight: 800,
  },
};
export default class LandingPage extends Component {
  static contextType = MixpanelContext;

  componentDidMount = () => {
    const mixpanel = this.context;
    if (mixpanel) {
      this.mixpanelTrigger();
    }
  };

  mixpanelTrigger = () => {
    const mixpanel = this.context;
    mixpanel.track('Landing Page Trigger', {
      source: 'Gigoshi Landing Page',
    });
  };

  render() {
    return (
      <Container id="landing-page-container" style={styles.container}>
        <Jumbotron id="marketing-message" style={styles.jumbotron}>
          <Row style={styles.row}>
            <Col id="message-col">
              <span id="message-header">
                <h1 style={styles.h1}>Pay in Crypto</h1>
                <h1 style={styles.h1}>Earn in Crypto</h1>
                <h1 style={styles.h1}>Anywhere in the world</h1>
              </span>
              <p id="message-desc">
                A freelance marketplace utilizing cryptocurrency
              </p>
              <Button
                id="get-started-btn"
                size="lg"
                style={styles.getStarted}>
                <Link to="/sign-in" style={{ textDecoration: 'none', color: '#FFF' }}>
                  Get Started
                </Link>
              </Button>
            </Col>
            <Col id="image-col">
              <img src={img} style={styles.image} />
            </Col>
          </Row>
        </Jumbotron>
        <PopularServices />
      </Container>
    );
  }
}

