import React, { Component } from 'react';
import VerifyPaymentCard from '../../components/admin-ui/VerifyPaymentCard';
import { Container } from 'reactstrap';
import '../pageStyles.css';
import config from '../../config';
import axios from 'axios';

const { ADMIN_API_URL } = config;

export default class VerifyPaymentPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transactions: [],
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount = async () => {
    //fetch all transactions that need to be verified
    const response = await axios.get(`${ADMIN_API_URL}/admin/proposals`, {
      params: { filter: 'verifyingPayment' },
    });
    const proposals = response.data.Proposals;
    this.setState({ transactions: proposals });
    window.addEventListener('resize', this.handleWindowResize);
  };

  handleWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  acceptPayment = async (transaction) => {
    const { freelancerId, createdTs, state, budget, clientUsername } =
      transaction;

    if (
      window.confirm(
        `Are you sure you want to verify this payment of $${budget} from ${clientUsername}?`,
      )
    ) {
      // Make api call to update state to in progress
      try {
        const body = { accepted: true, errorNote: '' };
        await axios.put(
          `${ADMIN_API_URL}/admin/freelancers/${freelancerId}/proposals/${createdTs}?currentState=${state}`,
          body,
        );

        let proposals = this.state.transactions;
        proposals = proposals.filter((p) => p !== transaction);
        this.setState({ transactions: proposals });
      } catch (error) {
        console.log(
          'Could not update proposal state to inProgress after trying to verify payment.',
        );
        console.log(error);
      }
    }
  };

  rejectPayment = async (transaction) => {
    const { freelancerId, createdTs, state, budget, clientUsername } =
      transaction;

    if (
      window.confirm(
        `Are you sure you want to reject this payment of $${budget} from ${clientUsername}?`,
      )
    ) {
      // Make api call to create note of rejected payment
      try {
        const body = {
          accepted: false,
          errorNote: `Invalid payment error while receiving funds from client. Please contact client.`,
        };
        await axios.put(
          `${ADMIN_API_URL}/admin/freelancers/${freelancerId}/proposals/${createdTs}?currentState=${state}`,
          body,
        );

        let proposals = this.state.transactions;
        proposals = proposals.filter((p) => p !== transaction);
        this.setState({ transactions: proposals });
      } catch (error) {
        console.log(
          'Could not update proposal state to payout error after trying to verify payment.',
        );
        console.log(error);
      }
    }
  };

  viewProposalClick = (proposalKey, transaction) => {
    const redirectUri = `/admin/proposal/${encodeURIComponent(proposalKey)}`;
    return this.props.history.push(redirectUri, {
      state: { transaction, page: 'verifyPayments' },
    });
  };

  viewJobClick = (jobKey) => {
    const jobUri = `/jobs/${encodeURIComponent(jobKey)}`;
    this.props.history.push(jobUri);
  };

  renderCards = () => {
    const { transactions, windowWidth } = this.state;

    return transactions.map((transaction) => {
      const { createdTs, freelancerId } = transaction;
      const proposalKey = `${createdTs}#${freelancerId}`;

      return (
        <VerifyPaymentCard
          transaction={transaction}
          key={createdTs}
          proposalKey={proposalKey}
          viewProposalClick={this.viewProposalClick}
          viewJobClick={this.viewJobClick}
          acceptPayment={this.acceptPayment}
          rejectPayment={this.rejectPayment}
          windowWidth={windowWidth}
        />
      );
    });
  };

  render() {
    return (
      <Container id="page-container" style={{ margin: '20px auto' }}>
        <h1>Verify Payments</h1>
        <div id="payment-verification-cards">{this.renderCards()}</div>
      </Container>
    );
  }
}
