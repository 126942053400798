import React, { Component } from 'react';
import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react';

export default class Authenticator extends Component {
  componentDidMount = () => {
    this.props.setNavbarHidden(true);
  };

  componentWillUnmount = () => {
    this.props.setNavbarHidden(false);
  };

  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <AmplifyAuthenticator>
          <AmplifySignUp slot="sign-up" formFields={[{ type: 'username' }, { type: 'password' }, { type: 'email' }]} />
        </AmplifyAuthenticator>
      </div>
    );
  }
}
