import React from "react";
import ReactDOM from "react-dom";
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MixpanelProvider } from "./MixpanelContext";
import ErrorBoundary from "./ErrorBoundary";

Amplify.configure(awsconfig);

ReactDOM.render(
  <MixpanelProvider>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </MixpanelProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
