/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import axios from 'axios';
import config from '../config';
import { Auth } from 'aws-amplify';
import {
  Card,
  CardBody,
  CardSubtitle,
  CardColumns,
  Container,
  Spinner,
  Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
const { PRODUCTDB_API_URL } = config;

export default class ClientJobProposals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Proposals: null,
    };
  }

  componentDidMount = async () => {
    const user = await Auth.currentUserInfo();
    const clientId = user.attributes.sub;
    const { jobTs } = this.props;
    const res = await axios.get(
      `${PRODUCTDB_API_URL}/clients/${clientId}/jobs/${jobTs}/proposals`,
    );
    const { Proposals, jobTitle } = res.data;
    this.jobKey = `${jobTs}#${clientId}`;
    this.setState({
      Proposals,
      jobTitle,
    });
  };

  renderProposals = () => {
    const { Proposals } = this.state;
    return (
      <CardColumns>
        {Proposals.map((proposal) => {
          const proposalKey = `${proposal.createdTs}#${proposal.freelancerId}`;
          const redirectUri = `/proposals/${encodeURIComponent(proposalKey)}`;
          console.log(redirectUri);
          const deadline = new Date(proposal.deadline).toLocaleString('en-US', {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
          });
          return (
            <Card className="proposals-grid-card" key={proposal.createdTs}>
              <CardBody>
                <CardSubtitle className="proposal-budget" tag="h5">
                  Budget: {proposal.budget} {proposal.currency}, Proposed
                  Deadline: {deadline}
                  <Link to={redirectUri} style={{ float: 'right' }}>
                    <Button
                      style={{
                        backgroundColor: '#3849E3',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}>
                      View Proposal
                    </Button>
                  </Link>
                </CardSubtitle>
              </CardBody>
            </Card>
          );
        })}
      </CardColumns>
    );
  };

  noProposals = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <h5>No Proposals yet</h5>
        <div>
          <Link to={`/jobs/${encodeURIComponent(this.jobKey)}`}>View Job</Link>
        </div>
      </div>
    );
  };

  render() {
    const { Proposals, jobTitle } = this.state;

    if (!Proposals)
      return (
        <Container>
          <Spinner type="grow" color="primary">
            {' '}
          </Spinner>
        </Container>
      );

    // show Job Details
    return (
      <Container>
        <h3 style={{ textAlign: 'center' }}>Proposals For: {jobTitle} </h3>

        {!Proposals.length ? this.noProposals() : this.renderProposals()}
      </Container>
    );
  }
}
