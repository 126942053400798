import React, { Component } from 'react';
import { Spinner, Container } from 'reactstrap';

export default class Loading extends Component {
    render() {
        return (
            <Container>
                <Spinner type="grow" color="primary" size={this.props.size}>{" "}</Spinner>
            </Container>
        );
    }
}
