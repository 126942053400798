import React, { Component } from 'react';
import ReleaseFundsCard from '../../components/admin-ui/ReleaseFundsCard';
import { Container } from 'reactstrap';
import '../pageStyles.css';
import config from '../../config';
import axios from 'axios';

const { ADMIN_API_URL } = config;

export default class ReleaseFundsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transactions: [],
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount = async () => {
    //fetch all transactions that need to be released
    const response = await axios.get(`${ADMIN_API_URL}/admin/proposals`, {
      params: { filter: 'releasingFunds' },
    });
    const proposals = response.data.Proposals;
    this.setState({ transactions: proposals });
    window.addEventListener('resize', this.handleWindowResize);
  };

  handleWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };


  acceptFundsRelease = async (transaction) => {
    const { freelancerId, createdTs, state, budget, freelancerUsername } = transaction;

    if (window.confirm(`Are you sure you want to release funds of amount $${budget} to ${freelancerUsername}?`)){
      // Make api call to update state to completed
      try {
        const body = { accepted: true, errorNote: '' };
        await axios.put(`${ADMIN_API_URL}/admin/freelancers/${freelancerId}/proposals/${createdTs}?currentState=${state}`, body);

        let proposals = this.state.transactions;
        proposals = proposals.filter((p) => p !== transaction);
        this.setState({ transactions: proposals });
      } catch (error) {
        console.log(
          'Could not update proposal state to completed after trying to release funds.',
        );
        console.log(error);
      }
    }
    
  };

  rejectFundsRelease = async (transaction) => {
    const { freelancerId, createdTs, state, freelancerUsername, budget } = transaction;

    if (window.confirm(`Are you sure you want reject releasing funds of amount $${budget} to ${freelancerUsername}?`)){
      try {
        const body = { accepted: false, errorNote: `Payout error while releasing funds to freelancer.` };
        await axios.put(`${ADMIN_API_URL}/admin/freelancers/${freelancerId}/proposals/${createdTs}?currentState=${state}`, body);

        let proposals = this.state.transactions;
        proposals = proposals.filter((p) => p !== transaction);
        this.setState({ transactions: proposals });
      } catch (error) {
        console.log(
          'Could not update proposal state to payout error after trying to release funds.',
        );
        console.log(error);
      }
    }
    // Make api call to create note of rejected payment
    
  };

  viewProposalClick = (proposalKey, transaction) => {
    const redirectUri = `/admin/proposal/${encodeURIComponent(proposalKey)}`;
    return this.props.history.push(redirectUri, { state: { transaction, page: 'releaseFunds' } });
  };

  viewJobClick = (jobKey) => {
    const jobUri = `/jobs/${encodeURIComponent(jobKey)}`;
    this.props.history.push(jobUri);
  } 

  renderCards = () => {
    const { transactions, windowWidth } = this.state;

    return transactions.map((transaction) => {
      const { createdTs, freelancerId } = transaction;
      const proposalKey = `${createdTs}#${freelancerId}`;

      return (
        <ReleaseFundsCard
          transaction={transaction}
          key={createdTs}
          proposalKey={proposalKey}
          viewProposalClick={this.viewProposalClick}
          viewJobClick={this.viewJobClick}
          acceptFundsRelease={this.acceptFundsRelease}
          rejectFundsRelease={this.rejectFundsRelease}
          windowWidth={windowWidth}
        />
      );
    });
  };

  render() {
    return (
      <Container id="page-container" style={{ margin: '20px auto' }}>
        <h1>Release Funds</h1>
        <div id="payment-verification-cards">{this.renderCards()}</div>
      </Container>
    );
  }
}
