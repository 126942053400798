/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:73eb29dc-5928-4ae5-ba52-5a1ece577f1f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Q0ybNYDMt",
    "aws_user_pools_web_client_id": "7pso2epbsjo62boc2uj2s3uelj",
    "oauth": {},
    "aws_user_files_s3_bucket": "gigoshi-ui-file-storage172558-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://nloswlokrjebvgkqswogarubqu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
