import Auth from '@aws-amplify/auth';
import React, { Component } from 'react';
import Forbidden from '../components/Forbidden';
import config from '../config';
import axios from 'axios';
import Loading from '../components/Loading';
import { Form, Input, FormGroup, Container } from 'reactstrap';
import { Redirect } from 'react-router';



const { GIGOSHI_WALLET_ADDR, PRODUCTDB_API_URL } = config;

export default class WalletAddressPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            unauthorized: false,
            proposal: null,
            transactionId: null
        };
    }

    componentDidMount = async () => {
        const user = await Auth.currentUserInfo();
        const currentUserId = user.attributes.sub;
        const { proposalKey } = this.props.match.params;
        const [proposalCreatedTs, freelancerId] = decodeURIComponent(proposalKey).split('#');

        let response;
        try {
            response = await axios.get(`${PRODUCTDB_API_URL}/freelancers/${freelancerId}/proposals/${proposalCreatedTs}`);
        } catch (error) {
            console.error(error);
            if (error.response.status === 403) {
                this.setState({ unauthorized: true });
                return;
            }

            throw error;
        }

        const { Proposal } = response.data;
        const clientId = Proposal.jobKey.split('#')[1];
        const isProposalForMyJob = currentUserId === clientId;

        if (!isProposalForMyJob) {
            this.setState({ unauthorized: true });
            return;
        }

        this.setState({
            proposal: Proposal,
        });
    };

    handleInputChange = (event) => {
        const { value, name } = event.target;

        this.setState(
            {
                [name]: value,
            },
        );
    };

    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({ submitLoading: true });
        await this.addTransactionId();
        this.setState({ submitLoading: false, done: true });
    }

    addTransactionId = async () => {
        const { createdTs: proposalTs, freelancerId, jobKey } = this.state.proposal;
        const { transactionId } = this.state;

        const [jobTs, clientId] = jobKey.split('#');
        await axios.put(`${PRODUCTDB_API_URL}/clients/${clientId}/jobs/${encodeURIComponent(jobTs)}?transactionId=${transactionId}&proposalTs=${encodeURIComponent(proposalTs)}&freelancerId=${freelancerId}`);
    }

    render() {
        const { unauthorized, proposal, transactionId, submitLoading, done } = this.state;

        if (unauthorized) {
            return <Forbidden />;
        }

        if (!proposal || submitLoading) {
            return <Loading />;
        }

        if (done) {
            return (
                <Container style={{ textAlign: 'center', marginTop: '10vh' }}>
                    <h3>{"You're All Set!"}</h3>
                    <p>We will verify that your payment was successful</p>
                </Container>
            );
        }

        if (proposal.state !== 'waitingForPayment') {
            const proposalKey = `${proposal.createdTs}#${proposal.freelancerId}`;
            const redirectUri = `/proposals/${encodeURIComponent(proposalKey)}`;
            return <Redirect to={redirectUri} />;
        }

        return (
            <Container style={{ textAlign: 'center', marginTop: '10vh' }}>
                <p style={{ marginBottom: 0 }}> <strong>Send proposed cost to </strong></p>
                <p> <strong>the following wallet address:</strong></p>
                <p style={{ marginBottom: "50px", fontSize: "14px" }}>{GIGOSHI_WALLET_ADDR}</p>
                <p><strong>then</strong></p>
                <Form onSubmit={this.handleSubmit} style={{ marginLeft: "15px", marginTop: "50px" }}>
                    <FormGroup className="form-field" style={{ margin: 10 }}>
                        <h3> Enter Transaction ID: </h3>
                        <Input
                            onChange={this.handleInputChange}
                            required
                            name="transactionId"
                            type="text"
                            id="transactionId"
                        />
                    </FormGroup>
                    <FormGroup className="form-field" style={{ margin: 10 }}>
                        <Input className="form-submit-btn" type="submit" disabled={!transactionId} />
                    </FormGroup>
                </Form>
            </Container >
        );
    }
}