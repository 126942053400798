import React, { Component } from 'react';
import { Button, Jumbotron } from 'reactstrap';
import Avatar from '../Avatar';

export default class VerifyPaymentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  render() {
    const { transaction, proposalKey, windowWidth } = this.props;
    const {
      createdTs,
      clientUsername,
      transactionId,
      budget,
      currency,
      jobKey,
      clientId,
      state,
      payoutErrorNote,
    } = transaction;

    let shortenedTransactionId = transactionId;

    if (windowWidth >= 1000 && windowWidth < 1200) {
      shortenedTransactionId = shortenedTransactionId.substring(0, 40) + '...';
    } else if (windowWidth < 1000) {
      shortenedTransactionId = shortenedTransactionId.substring(0, 30) + '...';
    }

    return (
      <div
        key={createdTs}
        style={{
          backgroundColor: '#eee',
          margin: '50px auto',
          borderRadius: 10,
          width: '85%',
        }}>
        <Jumbotron key={createdTs} style={{ margin: '20px' , padding: '10px 5px' }}>
          <div id="transaction-info">
            <span>
              <h5>
                Client: {clientUsername} <Avatar userId={clientId} />
              </h5>
            </span>
            <h5>
              Transaction Amount: {currency} {budget}
            </h5>
            <h5>Txn ID: {shortenedTransactionId}</h5>
            {state === 'invalidPayment' && (
              <h5>Error Note: {payoutErrorNote}</h5>
            )}
            <Button
              color="primary"
              style={{ marginRight: 10 }}
              onClick={() =>
                this.props.viewProposalClick(proposalKey, transaction)
              }>
              <b>View Proposal</b>
            </Button>
            <Button
              color="primary"
              style={{ marginRight: 10 }}
              onClick={() => this.props.viewJobClick(jobKey)}>
              <b>View Job</b>
            </Button>

            <span style={{ float: 'right' }}>
              <Button
                style={{ marginRight: 10 }}
                color="success"
                onClick={() => this.props.acceptPayment(transaction)}>
                <b>Accept</b>
              </Button>
              {state !== 'invalidPayment' && (
                <Button
                  color="danger"
                  onClick={() => this.props.rejectPayment(transaction)}>
                  <b>Reject</b>
                </Button>
              )}
            </span>
          </div>
        </Jumbotron>
      </div>
    );
  }
}
