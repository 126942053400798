/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import Loading from './Loading';
import axios from 'axios';
import config from '../config';
import { Alert, Button, Container, Tooltip } from 'reactstrap';
import { BsPencil, BsTrash, BsFillLockFill } from 'react-icons/bs';
import { Link, Redirect } from 'react-router-dom';
import UpdateProposal from './UpdateProposal';
import { deleteConversation, updateConversation } from '../graphql/mutations';
import { conversationsByUserID } from '../graphql/queries';
import { Storage } from 'aws-amplify';
import Forbidden from './Forbidden';
import InputTransactionId from './InputTransactionId';
import AttachedFiles from './AttachedFiles';

const { PRODUCTDB_API_URL } = config;

export default class ViewProposal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      proposal: null,
      isEditing: null,
      isMyProposal: null,
      isProposalForMyJob: null,
      deleteAlertOpen: false,
      updatedFields: null,
      username: null,
      acceptProposalLoading: false,
      unauthorized: false,
      addingFiles: false,
      deletingFiles: false,
      walletIdTip: false,
      editProposalTooltip: false,
      deleteProposalTootltip: false,
    };
  }

  componentDidMount = async () => {
    const user = await Auth.currentUserInfo();
    const currentUserId = user.attributes.sub;
    const { proposalKey } = this.props.match.params;
    const [proposalCreatedTs, freelancerId] =
      decodeURIComponent(proposalKey).split('#');
    const isMyProposal = currentUserId === freelancerId;

    let response;
    try {
      response = await axios.get(
        `${PRODUCTDB_API_URL}/freelancers/${freelancerId}/proposals/${proposalCreatedTs}`,
      );
    } catch (error) {
      console.error(error);
      if (error.response.status === 403) {
        this.setState({ unauthorized: true });
        return;
      }

      throw error;
    }

    const { Proposal } = response.data;
    const [jobTs, clientId] = Proposal.jobKey.split('#');
    const isProposalForMyJob = currentUserId === clientId;
    this.setState({
      proposal: Proposal,
      isMyProposal: isMyProposal,
      updatedFields: { ...Proposal },
      username: user.username,
      isProposalForMyJob: isProposalForMyJob,
    });
  };

  toggleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  onDeleteClick = () => {
    if (window.confirm('Are you sure you want to delete this proposal?')) {
      this.handleDelete();
    }
  };

  handleInputChange = (event) => {
    const { value, name } = event.target;
    const { updatedFields } = this.state;
    updatedFields[name] = value;
    this.setState({
      updatedFields,
    });
  };

  handleDateChange = (value) => {
    this.setState({ deadline: value });
  };

  handleSubmit = async () => {
    const {
      budget,
      currency,
      deadline,
      details,
      isAccepted,
      isJobCompleted,
      createdTs,
    } = this.state.updatedFields;

    const user = await Auth.currentUserInfo();
    const freelancerId = user.attributes.sub;

    const updateProposalData = {
      budget,
      deadline,
      currency,
      isAccepted,
      isJobCompleted,
      details,
    };
    try {
      const updateProposalResponse = await axios.put(
        PRODUCTDB_API_URL +
          `/freelancers/${freelancerId}/proposals/${createdTs}`,
        updateProposalData,
      );
      console.log('updateProposalResponse: ', updateProposalResponse);
      const updatedProposal = updateProposalResponse.data;
      this.setState({ proposal: updatedProposal, isEditing: false });
    } catch (error) {
      console.log(error);
    }
  };

  handleDelete = async () => {
    const { proposal } = this.state;
    const { freelancerId, createdTs, jobKey } = proposal;
    const clientId = jobKey.split('#')[1];
    const channelId = `${freelancerId}#${createdTs}`;

    try {
      await axios.delete(
        `${PRODUCTDB_API_URL}/freelancers/${freelancerId}/proposals/${createdTs}`,
      );

      /*
        NOTE:
        For the chat list Appsync subscription to update the client on delete we need to delete the conversation dynamodb records using an Appsync mutation 
        (can't circumvent Appsync and use DynamoDB APIs directly)
        Appsync doesn't allow you to customize the PK and SK of your tables so the PK: id and SK is unused.
        Appsync gives you the option to create Global Secondary Indexes, but unfortunately, you can't use GSI keys to delete a record using Appsync, 
        so we must fetch both Conversation records, extract the id fields, and use the deleteConversation Appsync mutation to delete them.
      */
      // get both instances of the conversation
      const getConversationRes = await API.graphql(
        graphqlOperation(conversationsByUserID, {
          userID: freelancerId,
          channelID: { eq: channelId },
        }),
      );

      const getInverseConversationRes = await API.graphql(
        graphqlOperation(conversationsByUserID, {
          userID: clientId,
          channelID: { eq: channelId },
        }),
      );

      const [conversation] =
        getConversationRes.data?.conversationsByUserID?.items;
      const [inverseConversation] =
        getInverseConversationRes.data?.conversationsByUserID?.items;

      // delete associated conversation and it's inverse from proposals table.
      await API.graphql(
        graphqlOperation(deleteConversation, {
          input: {
            id: conversation.id,
          },
        }),
      );
      await API.graphql(
        graphqlOperation(deleteConversation, {
          input: {
            id: inverseConversation.id,
          },
        }),
      );

      // <Redirect to="/proposals" />;
      this.props.history.push('/proposals');
    } catch (error) {
      console.log('Couldnt delete proposal');
      console.log(error);
    }
  };

  downloadFile = async (file) => {
    console.log(file);
    const response = await Storage.get(file.location, { download: true });
    console.log(response);

    const url = URL.createObjectURL(response.Body);
    const link = document.createElement('a');
    link.href = url;
    link.download = file.name || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        link.removeEventListener('click', clickHandler);
      }, 150);
    };
    link.addEventListener('click', clickHandler, false);
    link.click();
    return link;
  };

  acceptProposalConfirmation = async () => {
    const { budget, currency, freelancerUsername } = this.state.proposal;
    if (
      window.confirm(
        `Are you sure you want to accept proposal from ${freelancerUsername} for ${budget} ${currency}?`,
      )
    ) {
      await this.acceptProposal();
    }
  };

  releaseFundsConfirmation = async () => {
    const { budget, currency, freelancerUsername } = this.state.proposal;
    if (
      window.confirm(
        `Are you sure you want to release ${budget} ${currency} to ${freelancerUsername}? This action is irreversible`,
      )
    ) {
      await this.releaseFunds();
    }
  };

  acceptProposal = async () => {
    this.setState({ acceptProposalLoading: true });
    const { createdTs: proposalTs, freelancerId, jobKey } = this.state.proposal;
    const [jobTs, clientId] = jobKey.split('#');
    await axios.put(
      `${PRODUCTDB_API_URL}/clients/${clientId}/jobs/${encodeURIComponent(
        jobTs,
      )}?accepted=true&proposalTs=${encodeURIComponent(
        proposalTs,
      )}&freelancerId=${freelancerId}`,
    );

    // get conversation records to get ids (annoying but you need the id to update the record)
    const channelId = `${freelancerId}#${proposalTs}`;
    const getConversationRes = await API.graphql(
      graphqlOperation(conversationsByUserID, {
        userID: freelancerId,
        channelID: { eq: channelId },
      }),
    );
    const getInverseConversationRes = await API.graphql(
      graphqlOperation(conversationsByUserID, {
        userID: clientId,
        channelID: { eq: channelId },
      }),
    );
    const [conversation] =
      getConversationRes.data?.conversationsByUserID?.items;
    const [inverseConversation] =
      getInverseConversationRes.data?.conversationsByUserID?.items;

    // update conversations
    const updateConversationInput = {
      id: conversation.id,
      isAccepted: true,
    };
    const updateInverseConversationInput = {
      id: inverseConversation.id,
      isAccepted: true,
    };
    await API.graphql(graphqlOperation(updateConversation, { input: updateConversationInput }));
    await API.graphql(graphqlOperation(updateConversation, { input: updateInverseConversationInput }));
    
    this.setState({ acceptProposalLoading: false });
    window.location.reload();
  };

  releaseFunds = async () => {
    this.setState({ releaseFundsLoading: true });
    const { createdTs: proposalTs, freelancerId, jobKey } = this.state.proposal;
    const [jobTs, clientId] = jobKey.split('#');
    await axios.put(
      `${PRODUCTDB_API_URL}/clients/${clientId}/jobs/${encodeURIComponent(
        jobTs,
      )}?release=true&proposalTs=${encodeURIComponent(
        proposalTs,
      )}&freelancerId=${freelancerId}`,
    );
    this.setState({ releaseFundsLoading: false });
    window.location.reload();
  };

  clientStateMachine = () => {
    const { acceptProposalLoading, releaseFundsLoading, proposal } = this.state;
    const { isAccepted, state, jobKey } = proposal;

    if (!isAccepted) {
      return (
        <Button onClick={this.acceptProposalConfirmation} color="success">
          {acceptProposalLoading ? <Loading size="sm" /> : 'Accept Proposal'}
        </Button>
      );
    }

    if (state === 'waitingForPayment') {
      return <InputTransactionId proposal={proposal} />;
    }

    if (state === 'verifyingPayment' || state === 'invalidPayment' ) {
      return (
        <>
          <p style={{ marginTop: '30px' }}>
            <strong>
              Waiting for Payment to be verified. This may take a few hours...
            </strong>
          </p>
          <p>
            <i>Transaction ID:</i> {proposal.transactionId}
          </p>
        </>
      );
    }

    if (state === 'inProgress') {
      return (
        <Button
          onClick={this.releaseFundsConfirmation}
          color="success"
          style={{ width: '145px' }}>
          {releaseFundsLoading ? <Loading size="sm" /> : 'Release Funds'}
        </Button>
      );
    }

    if (state === 'releasingFunds' || state === 'payoutError') {
      return (
        <p style={{ marginTop: '30px' }}>
          <strong>
            Waiting for Payment to be released. This may take a few hours...
          </strong>
        </p>
      );
    }

    if (state === 'completed') {
      const redirectUri = `/jobs/${encodeURIComponent(jobKey)}`;
      return (
        <Button
          onClick={() => this.props.history.push(redirectUri)}
          color="success">
          Job Complete
        </Button>
      );
    }
  };

  freelancerStateMachine = () => {
    const { isAccepted, state, jobKey } = this.state.proposal;

    if (!isAccepted) {
      return null;
    }

    if (state === 'waitingForPayment') {
      return (
        <p style={{ marginTop: '30px' }}>
          <strong>Waiting on Client to transfer funds.</strong>
        </p>
      );
    }

    if (state === 'verifyingPayment') {
      return (
        <p style={{ marginTop: '30px' }}>
          <strong>
            Waiting for Payment to be verified. This may take a few hours...
          </strong>
        </p>
      );
    }

    if (state === 'inProgress') {
      return (
        <p style={{ marginTop: '30px' }}>
          <strong>
            This Job is in Progress. When ready the Client will release funds.
          </strong>
        </p>
      );
    }

    if (state === 'releasingFunds') {
      return (
        <p style={{ marginTop: '30px' }}>
          <strong>
            Waiting for Payment to be released. This may take a few hours...
          </strong>
        </p>
      );
    }

    if (state === 'completed') {
      const redirectUri = `/jobs/${encodeURIComponent(jobKey)}`;
      return (
        <Button
          onClick={() => this.props.history.push(redirectUri)}
          color="success">
          Job Complete
        </Button>
      );
    }
  };

  toggleEditFiles = (editType) => {
    const { addingFiles, deletingFiles } = this.state;
    if (editType === 'add') {
      this.setState({ addingFiles: !addingFiles });
      // console.log('add');
    } else if (editType === 'delete') {
      // console.log('delete');
      this.setState({ deletingFiles: !deletingFiles });
    }
  };

  /*
   * Delete attached files for a proposal
   */
  deleteAttachedFiles = async (filesToDelete) => {
    let proposal = { ...this.state.proposal };
    let attachedFiles = proposal.attachedFiles;

    const { createdTs, freelancerId } = proposal;
    if (window.confirm(`Are you sure you want to remove the selected files?`)) {
      try {
        for (const file of filesToDelete) {
          const fileLocationURI = encodeURIComponent(file.location);
          console.log('Deleting: ', file);
          // Deleting the file from the proposal record in DyanmoDB
          const response = await axios.delete(
            `${PRODUCTDB_API_URL}/freelancers/${freelancerId}/proposals/${createdTs}/files/${fileLocationURI}`,
          );

          if (response.status === 200) {
            // Deleteing the file from the S3 bucket
            await Storage.remove(file.location);

            // Deleteing the file from the UI
            attachedFiles = attachedFiles.filter((f) => f !== file);
          }
        }
      } catch (error) {
        console.log('Error trying to delete attachment(s): ');
        console.log(error);
      }
    }

    // Setting the proposal state in the UI after file deletion
    proposal.attachedFiles = attachedFiles;
    this.setState({ proposal });
    this.toggleEditFiles('delete');
  };

  /*
   * Add new files for a proposal
   */ x;
  addNewFiles = async (filesToAdd) => {
    let proposal = { ...this.state.proposal };
    let attachedFiles = proposal.attachedFiles;
    const { createdTs, freelancerId } = proposal;
    let currentlyAdding;

    try {
      for (const file of filesToAdd) {
        const requestBody = { name: file.file.name, location: file.location };
        console.log('Adding: ', file.file.name, ' to location ', file.location);
        currentlyAdding = file.file.name;
        // Upload file info to DynamoDB and perform next steps based on response
        const response = await axios.post(
          `${PRODUCTDB_API_URL}/freelancers/${freelancerId}/proposals/${createdTs}/files`,
          requestBody,
        );

        // Add file to S3 bucket
        await Storage.put(file.location, file.file);

        // Add file to UI
        attachedFiles.push(requestBody);
      }
    } catch (error) {
      // If file already exists, 409
      if (error.response.status === 409) {
        window.alert(`The file ${currentlyAdding} already exists!`);
      }
      // If issue adding file to DynamoDB
      else if (error.response.status === 501) {
        window.alert(
          `There was problem while trying to upload the file ${currentlyAdding}. Please retry uploading it.`,
        );
      }
      console.log('Error uploading new file(s): ');
      console.log(error);
    }

    // Setting the proposal state in the UI after file deletion
    if (this.state.proposal.attachedFiles !== attachedFiles) {
      proposal.attachedFiles = attachedFiles;
      this.setState({ proposal });
    }
    this.toggleEditFiles('add');
  };

  render() {
    const {
      proposal, //
      isEditing,
      isMyProposal,
      isProposalForMyJob,
      updatedFields,
      unauthorized,
      deletingFiles,
      addingFiles,
      walletIdTip,
      editProposalTooltip,
      deleteProposalTootltip,
    } = this.state;

    if (unauthorized) {
      return <Forbidden />;
    }

    if (!proposal) {
      return <Loading />;
    }

    const {
      budget,
      createdTs,
      currency,
      deadline,
      details,
      freelancerUsername,
      jobTitle,
      attachedFiles,
      walletId,
      isAccepted,
      isJobCompleted,
      clientUsername,
    } = proposal;

    const { proposalKey } = this.props.match.params;
    const filesAvailable =
      attachedFiles && attachedFiles !== null && attachedFiles.length
        ? true
        : false;

    if (isEditing) {
      return (
        <UpdateProposal
          updatedFields={updatedFields}
          handleSubmit={this.handleSubmit}
          handleInputChange={this.handleInputChange}
          handleDateChange={this.handleDateChange}
          proposalKey={proposalKey}
          toggleEditing={this.toggleEditing}
        />
      );
    }

    return (
      <Container id="page-container">
        <h2>
          Proposal For: {jobTitle}
          {isMyProposal && !isAccepted && (
            <span style={{ float: 'right' }}>
              <span>
                <BsPencil
                  id="edit-icon"
                  size="20px"
                  onClick={this.toggleEditing}
                  cursor="pointer"
                  color="blue"
                />
                <Tooltip
                  target="edit-icon"
                  placement="top"
                  isOpen={editProposalTooltip}
                  toggle={() =>
                    this.setState({ editProposalTooltip: !editProposalTooltip })
                  }>
                  Edit this proposal.
                </Tooltip>
              </span>
              <span>
                <BsTrash
                  id="delete-icon"
                  size="20px"
                  style={{ marginLeft: '10px' }}
                  onClick={this.onDeleteClick}
                  cursor="pointer"
                  color="red"
                />
                <Tooltip
                  target="delete-icon"
                  placement="top"
                  isOpen={deleteProposalTootltip}
                  toggle={() =>
                    this.setState({
                      deleteProposalTootltip: !deleteProposalTootltip,
                    })
                  }>
                  Delete this proposal.
                </Tooltip>
              </span>
            </span>
          )}
        </h2>
        <p>
          <b>Proposal Freelancer:</b> {freelancerUsername}
        </p>
        <p>
          <b>Job Client: </b> {clientUsername}
        </p>
        <p>
          <b>Submited date:</b>{' '}
          {new Date(createdTs).toLocaleString('en-US', {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          })}
        </p>
        <p>
          <b>Proposed Cost:</b> {budget} {currency}
        </p>
        <p>
          <b>Proposed Deadline:</b>{' '}
          {new Date(deadline).toLocaleString('en-US', {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          })}
        </p>
        <p>
          <b>Details:</b> {details === null || !details ? 'None.' : details}
        </p>
        {walletId && (
          <p id="wallet-id">
            <b>
              Wallet Id:{' '}
              <BsFillLockFill color="#DAA520" style={{ marginBottom: 2 }} />
            </b>
            {walletId}
            <Tooltip
              target="wallet-id"
              placement="top-start"
              isOpen={walletIdTip}
              toggle={() => this.setState({ walletIdTip: !walletIdTip })}>
              Your wallet ID is protected and not made public.
            </Tooltip>
          </p>
        )}
        {filesAvailable ? (
          <AttachedFiles
            proposal={proposal}
            isMyProposal={isMyProposal}
            deletingFiles={deletingFiles}
            addingFiles={addingFiles}
            toggleEditFiles={this.toggleEditFiles}
            deleteAttachedFiles={this.deleteAttachedFiles}
            addNewFiles={this.addNewFiles}
          />
        ) : (
          <p>
            <b>No Attachments</b>
          </p>
        )}

        {isProposalForMyJob
          ? this.clientStateMachine()
          : this.freelancerStateMachine()}
      </Container>
    );
  }
}
