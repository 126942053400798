const PRODUCTDB_API_URL = 'https://t1xaqrzat0.execute-api.us-east-1.amazonaws.com/dev';
const ADMIN_API_URL = 'https://dczzrphxg9.execute-api.us-east-1.amazonaws.com/dev';
const MIXPANEL_TOKEN = 'd16807f9c0295a2b05e667adaa24b6bf';
const GIGOSHI_WALLET_ADDR = '0x623F0ebb9BBC0A19978104A8CCE7C75d71e5D897';
const GIGOSHI_ADMIN_GROUP = 'Gigoshi-Admins';

const config = {
    PRODUCTDB_API_URL, 
    ADMIN_API_URL,
    GIGOSHI_WALLET_ADDR,
    MIXPANEL_TOKEN,
    GIGOSHI_ADMIN_GROUP
};

export default config;