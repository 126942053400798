/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Storage } from 'aws-amplify';
import { Button, FormGroup, Input } from 'reactstrap';
import { FaFileImage, FaFilePdf, FaFileWord } from 'react-icons/fa';
import config from '../config';
import UploadFile from './UploadFile';
const { PRODUCTDB_API_URL } = config;

export default class AttachedFiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filesToDelete: [],
      filesToAdd: [],
    };
  }

  renderAttachedFiles = () => {
    const { proposal, deletingFiles } = this.props;
    const { attachedFiles } = proposal;
    const files = attachedFiles;
    const deleteCheckbox = deletingFiles ? 'visible' : 'hidden';

    const { filesToDelete } = this.state;

    if (!files.length || !files) return;
    return files.map((file) => {
      const extension = file.name.split('.').pop();
      const isChecked = filesToDelete.includes(file);
      let fileName = file.name.split('.')[0];
      if(fileName.length > 20) fileName = fileName.substring(0, 20) + '...';
      return (
        <div
          key={file.name}
          id="file-upload-block"
          style={{
            fontSize: 12,
            textAlign: 'center',
            border: '1px solid #D1D1D1',
            borderRadius: 5,
            padding: 2,
            margin: 4,
            float: 'left',
          }}>
          <p>{fileName}</p>
          {this.fileTypeIcon(extension, file)}
          <div style={{ visibility: deleteCheckbox, margin: 'auto' }}>
            <FormGroup check style={{ marginLeft: '45%', marginRight: '45%' }}>
              <Input type="checkbox" onChange={(e) => this.selectFiles(e, file)} checked={isChecked} />
            </FormGroup>
          </div>
        </div>
      );
    });
  };

  fileTypeIcon = (extension, file) => {
    if (extension === 'doc' || extension === 'docx') {
      return (
        <FaFileWord
          id="file-upload-icon"
          style={{ fontSize: 100, padding: '5px 5px 10px 5px', cursor: 'pointer' }}
          onClick={() => this.downloadFile(file)}
        />
      );
    } else if (extension === 'pdf') {
      return (
        <FaFilePdf
          id="file-upload-icon"
          style={{ fontSize: 100, padding: '5px 5px 10px 5px', cursor: 'pointer' }}
          onClick={() => this.downloadFile(file)}
        />
      );
    }
    if (extension === 'jpeg' || extension === 'jpg' || extension === 'png') {
      return (
        <FaFileImage
          id="file-upload-icon"
          style={{ fontSize: 100, padding: '5px 5px 10px 5px', cursor: 'pointer' }}
          onClick={() => this.downloadFile(file)}
        />
      );
    }
  };

  downloadFile = async (file) => {
    const response = await Storage.get(file.location, { download: true });
    const url = URL.createObjectURL(response.Body);
    const link = document.createElement('a');
    link.href = url;
    link.download = file.name || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        link.removeEventListener('click', clickHandler);
      }, 150);
    };
    link.addEventListener('click', clickHandler, false);
    link.click();
    return link;
  };

  selectFiles = (e, file) => {
    const checked = e.target.checked;
    const { filesToDelete } = this.state;
    let selected = filesToDelete;

    if (checked) {
      selected.push(file);
    } else if (!checked) {
      selected = selected.filter((f) => f.name !== file.name);
    }
    this.setState({ filesToDelete: selected });
  };

  onActionButtonClick = (action) => {
    if (action === 'delete') {
      const { deletingFiles } = this.props;
      if (deletingFiles) {
        this.setState({ filesToDelete: [] });
      }
      this.props.toggleEditFiles('delete');
    } else if (action === 'add') {
      const { addingFiles } = this.props;
      if (addingFiles) {
        this.setState({ filesToAdd: [] });
      }
      this.props.toggleEditFiles('add');
    }
  };

  setAttachments = (s3UploadData) => {
    let filesData = [];
    for (let i = 0; i < s3UploadData.length; i++) {
      filesData.push({ name: s3UploadData[i].file.name, location: s3UploadData[i].location });
    }
    this.setState({ filesToAdd: s3UploadData });
  };

  confirmAttachmentsChanges = (type) => {
    if (type === 'delete') {
      const { filesToDelete } = this.state;
      this.props.deleteAttachedFiles(filesToDelete);
      this.setState({ filesToDelete: [] });
    } else if (type === 'add') {
      const { filesToAdd } = this.state;
      this.props.addNewFiles(filesToAdd);
      this.setState({ filesToAdd: [] });
    }
  };

  render() {
    const { isJobCompleted } = this.props.proposal;
    const { filesToAdd, filesToDelete } = this.state;
    const { isMyProposal, addingFiles, deletingFiles, proposal } = this.props;
    const uploadFileVisibility = addingFiles ? 'visible' : 'hidden';
    const deleteButtonVisibility = deletingFiles ? 'visible' : 'hidden';

    return (
      <div id="files-container">
        <div id="attached-files" style={{ display: 'flex' }}>
          {this.renderAttachedFiles()}
          {isMyProposal && !isJobCompleted && !proposal.isClosed && (
            <div id="attachments-action-buttons" style={{ margin: 'auto 10px' }}>
              <Button
                style={{ margin: 5, backgroundColor: '#3849E3', border: 'none' }}
                disabled={deletingFiles || addingFiles}
                onClick={() => this.onActionButtonClick('add')}>
                Add Files
              </Button>
              <Button
                style={{ margin: 5, backgroundColor: '#B22222', border: 'none' }}
                disabled={deletingFiles || addingFiles}
                onClick={() => this.onActionButtonClick('delete')}>
                Delete Files
              </Button>
            </div>
          )}
        </div>
        <div id="delete-file-actions" style={{ visibility: deleteButtonVisibility }}>
          <Button
            disabled={!filesToDelete.length}
            color="success"
            style={{ margin: 5 }}
            onClick={() => this.confirmAttachmentsChanges('delete')}>
            Submit
          </Button>
          <Button
            color="danger"
            style={{ margin: 5, backgroundColor: '#B22222', border: 'none' }}
            onClick={() => this.onActionButtonClick('delete')}>
            Cancel
          </Button>
        </div>
        <div id="upload-file-actions" style={{ visibility: uploadFileVisibility, maxWidth: '80%', minWidth: '60%' }}>
          <UploadFile setAttachments={this.setAttachments} isJob={false} />
          <Button
            disabled={!filesToAdd.length}
            color="success"
            style={{ margin: 5 }}
            onClick={() => this.confirmAttachmentsChanges('add')}>
            Submit
          </Button>
          <Button
            color="danger"
            style={{ margin: 5, backgroundColor: '#B22222', border: 'none' }}
            onClick={() => this.onActionButtonClick('add')}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}