import React, { Component } from 'react';
import { Container } from 'reactstrap';

export default class Forbidden extends Component {
    render() {
        return (
            <Container style={{ textAlign: 'center', marginTop: '10px' }}>
              <h3>Forbidden</h3>
              <p>Are you lost? 👀</p>
            </Container>
          );
    }
}
