import React, { Component } from 'react';
import { Container, FormGroup, Input, Label } from 'reactstrap';
import ReleaseFundsPage from './ReleaseFundsPage';
import VerifyPaymentPage from './VerifyPaymentPage';
import PaymentErrorPage from './PaymentErrorPage';

const VERIFY_PAYMENTS = 'vp';
const RELEASE_FUNDS = 'rf';
const PAYMENT_ERROR = 'pe';

export default class AdminPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: null,
        };
    }

    onValueChange = (event) => {
        this.setState({
            checked: event.target.value
        });
    }

    render() {
        const { checked } = this.state;
        return (
            <Container>
                <FormGroup tag="fieldset">
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="radio"
                                value={VERIFY_PAYMENTS}
                                checked={checked === VERIFY_PAYMENTS}
                                onChange={this.onValueChange}
                            />{' '}
                            Verify Payments
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="radio"
                                value={RELEASE_FUNDS}
                                checked={checked === RELEASE_FUNDS}
                                onChange={this.onValueChange}
                            />
                            Release Funds
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="radio"
                                value={PAYMENT_ERROR}
                                checked={checked === PAYMENT_ERROR}
                                onChange={this.onValueChange}
                            />
                            Invalid Payments / Payout Errors
                        </Label>
                    </FormGroup>

                    {checked === VERIFY_PAYMENTS && <VerifyPaymentPage history={this.props.history} />}
                    {checked === RELEASE_FUNDS  && <ReleaseFundsPage history={this.props.history} />}
                    {checked === PAYMENT_ERROR  && <PaymentErrorPage history={this.props.history} />}
                </FormGroup>
            </Container>
        );
    }
}