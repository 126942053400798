import React, { Component } from 'react';
import axios from 'axios';
import './pageStyles.css';
import { JobsContext } from '../jobContext';
import config from '../config';
import { Auth } from 'aws-amplify';
import { Jumbotron, Button } from 'reactstrap';
import Loading from '../components/Loading';

const { PRODUCTDB_API_URL } = config;

export default class JobsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Jobs: null,
    };
  }
  static contextType = JobsContext;

  componentDidMount = async () => {
    const user = await Auth.currentUserInfo();
    const clientId = user.attributes.sub;
    const res = await axios.get(
      PRODUCTDB_API_URL + `/clients/${clientId}/jobs`,
    );
    const { Jobs } = res.data;
    this.setState({
      Jobs,
      clientId,
    });
  };

  jobStatus = (job) => {
    const { isJobCompleted, acceptedProposalId, state } = job;

    if (isJobCompleted || state === 'completed') return <p>Completed</p>;

    if (acceptedProposalId && state === 'inProgress') return <p>In Progress</p>;
    else if (
      acceptedProposalId &&
      (state === 'verifyingPayment' || state === 'invalidPayment')
    )
      return <p>Verifying Payment</p>;
    else if (
      acceptedProposalId &&
      (state === 'releasingFunds' || state === 'payoutError')
    )
      return <p>Releasing Funds</p>;

    return <p>Open</p>;
  };

  renderJobs = () => {
    const { Jobs, clientId } = this.state;

    if (!Jobs) return <Loading />;

    if (!Jobs.length) return <p style={{ textAlign: 'center' }}>No Jobs Yet</p>;

    let jobList = [];
    for (let job of Jobs) {
      const {
        title,
        description,
        budgetMax,
        currency,
        createdTs,
        isJobCompleted,
        acceptedProposalId,
        deadline
      } = job;

      const cardDescription =
        description.length <= 300
          ? description
          : description.substring(0, 298) + '...';
      const jobKey = `${createdTs}#${clientId}`;
      const redirectUri = `/jobs/${encodeURIComponent(jobKey)}`;

      jobList.push(
        <div
          style={{ backgroundColor: '#eee', margin: '20px' }}
          key={createdTs}>
          <Jumbotron key={createdTs} style={{ margin: '20px' }}>
            <h1 className="display-3">{title}</h1>
            <p className="lead">{cardDescription}</p>
            <hr className="my-2" />
            <p>
              {budgetMax} {currency}{' '}
              <span style={{ fontStyle: 'italic' }}>
                {' '}
                <b>Posted: </b>
                {new Date(createdTs).toLocaleString('en-US', {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                })}
                {" - "}
                <b>Due: </b>
                {new Date(deadline).toLocaleString('en-US', {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                })}
              </span>
            </p>
            {this.jobStatus(job)}
            {isJobCompleted ? (
              <p className="lead">
                <Button
                  onClick={() =>
                    this.props.history.push(
                      `/proposals/${encodeURIComponent(acceptedProposalId)}`,
                    )
                  }
                  color="success"
                  style={{
                    marginBottom: '10px',
                    marginLeft: '90%',
                  }}>
                  View Proposal
                </Button>
              </p>
            ) : (
              <p className="lead">
                <Button
                  onClick={() => this.props.history.push(redirectUri)}
                  color="primary"
                  style={{
                    marginBottom: '10px',
                    marginLeft: '90%',
                  }}>
                  View Job
                </Button>
              </p>
            )}
          </Jumbotron>
        </div>,
      );
    }
    return jobList;
  };

  render() {
    return (
      <div id="page-container">
        <h1 style={{ textAlign: 'center' }}>My Jobs</h1>
        <div>{this.renderJobs()}</div>
      </div>
    );
  }
}
