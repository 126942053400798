import './App.css';
import React, { Component } from 'react';
import { Auth, Hub } from 'aws-amplify';
import axios from 'axios';
import Public from './components/Public';
import Protected from './components/Protected';
import config from './config';


axios.interceptors.request.use(async (req) => {
  // dont add JWT to Amplify http requests and graphQL requests
  if (req.headers['amz-sdk-request'] || req.headers['x-amz-user-agent'])
    return req;

  const session = await Auth.currentSession();
  const { jwtToken } = session.idToken;
  req.headers.Authorization = `Bearer ${jwtToken}`;
  return req;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const isAdmin = (
        session.idToken.payload["cognito:groups"]
          ? session.idToken.payload["cognito:groups"].includes(config.GIGOSHI_ADMIN_GROUP)
          : false
      );

      this.setState({ user: { ...user, isAdmin } });
    } catch (error) {
      console.log('No signed in user');
    }

    Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signIn':
          return this.setState({ user: data.payload.data });
        case 'signOut':
          return this.setState({ user: null });
      }
    });
  }

  render() {
    const { user } = this.state;

    if (!user || !Object.keys(user).length) {
      return <Public />;
    }

    return <Protected user={user} />;

  }
}

export default App;
