import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import Authenticator from './Authenticator';
import Navbar from './Navbar';

export default class Public extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navbarHidden: false,
    };
  }

  setNavbarHidden = (navbarHidden) => {
    this.setState({ navbarHidden });
  };

    render() {
        const { navbarHidden } = this.state;
        return (
            <div>
                <Router>
                    <Navbar hide={navbarHidden} unauthenticated={true} />
                    <Switch>
                        <Route exact path="/" component={LandingPage} />
                        <Route render={() => <Authenticator setNavbarHidden={this.setNavbarHidden} />} />
                    </Switch>
                </Router>
            </div>
        );
    }
}
