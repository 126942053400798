/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import API, { graphqlOperation } from '@aws-amplify/api';
import '@aws-amplify/pubsub';
import './Chat.css';

import { createMessage } from '../../graphql/mutations';

// built following this article: https://medium.com/swlh/building-a-chat-app-on-react-and-aws-amplify-1-c96d456dffc1

export default function Chat(props) {
	const [messageBody, setMessageBody] = useState('');

	const chatBottomRef = useRef(null);

	const scrollToBottom = () => {
		chatBottomRef.current.scrollIntoView(); // TODO: look into args for this function to make auto-scrolling look better
	};

	// scrolls to bottom of chat when new messages come in
	useEffect(scrollToBottom, [props.messages.length]);

	const handleChange = (event) => {
		setMessageBody(event.target.value);
	};
	
	const handleSubmit = async (event) => {
		event.preventDefault();
		event.stopPropagation();
		
		const input = {
			channelID: props.channelId,
			author: props.user.attributes.sub,
			body: messageBody.trim(),
			read: false
		};
		
		try {
			setMessageBody('');
			await API.graphql(graphqlOperation(createMessage, { input }));
			props.readMessages();
		} catch (error) {
			console.warn(error);
		}
	};

	const renderMessages = () => {
		const { messages, hasUnreadMessages, user } = props;
		let foundFirstUnreadMessage = false;
		let messageElements = [];
		for (let message of messages) {
			// add line to separate read/unread messages before first unread message
			if (hasUnreadMessages && !foundFirstUnreadMessage && message.author !== user.attributes.sub && !message.read) {
				messageElements.push(
					<div key={hasUnreadMessages}>
						<p className="unread-line-text">unread messages</p>
						<hr className="unread-line"/>
					</div>
				);
				foundFirstUnreadMessage = true;
			}
			messageElements.push (
				<div
					key={message.id}
					className={message.author === props.user.attributes.sub ? 'message me' : (message.read ? 'message': 'message-unread')}>
						{message.body}
				</div>
			);
		}

		return messageElements;
	};

	// chat bar should be disabled if person is freelancer and client hasn't responded to chat yet
	const isChatBarDisabled = props.user === null || (!props.isAccepted && props.messages.length === 1 && props.messages[0].author === props.user.attributes.sub);
	const chatBarPlaceholderText = isChatBarDisabled ? "disabled, waiting for reply..." : "Type your message here...";
	return (
		<div className="chat-app">
			{props.user && (
				<div className="header">
					<div><strong>{props.otherUsername}</strong></div>
					<small className="text-muted">{props.jobTitle}</small>
				</div>
			)}
			<div className="message-container">
				<div className="messages">
					<div className="messages-scroller">
						{props.messages && renderMessages()}
						<div ref={chatBottomRef}/>
					</div>
				</div>
				<div className="chat-bar">
					<form onSubmit={handleSubmit}>
						<input
							type="text"
							name="messageBody"
							placeholder={chatBarPlaceholderText}
							disabled={isChatBarDisabled} 
							onChange={handleChange}
							value={messageBody}
						/>
					</form>
				</div>
			</div>
		</div>
	);
}