import React, { Component, createContext } from 'react';
// import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';
import config from './config';

const MixpanelContext = createContext();
mixpanel.init(config.MIXPANEL_TOKEN);

class MixpanelProvider extends Component {
  render() {
    return <MixpanelContext.Provider value={mixpanel}>{this.props.children}</MixpanelContext.Provider>;
  }
}

const MixpanelConsumer = MixpanelContext.Consumer;
export { MixpanelContext, MixpanelProvider, MixpanelConsumer };
