/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { FormGroup, Input, Button } from 'reactstrap';
import { BsFillTrashFill, BsFolderFill } from 'react-icons/bs';
import { FaFileImage, FaFilePdf, FaFileWord } from 'react-icons/fa';

export default class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.hiddenFileUploadRef = React.createRef(null);
    this.state = {
      fileData: [],
    };
  }

  saveFiles = async (event) => {
    const selectedFiles = event.target.files;
    const { username } = await Auth.currentAuthenticatedUser();
    const s3UploadData = this.state.fileData;
    for (let i = 0; i < selectedFiles.length; i++) {
      const currFile = selectedFiles[i];
      const location = `${username}/files/${currFile.name}`;
      s3UploadData.push({ file: currFile, location: location });
    }
    this.props.setAttachments(s3UploadData);
    this.setState({ fileData: s3UploadData });
  };

  renderFileIcons = () => {
    const { fileData } = this.state;
    if (!fileData.length) return;
    return fileData.map((data) => {
      const file = data.file;
      const extension = file.name.split('.').pop();
      let fileName = file.name.split('.')[0];
      if (fileName.length > 20) fileName = fileName.substring(0, 20) + '...';
      return (
        <div
          key={file.name}
          id="file-upload-block"
          style={{
            fontSize: 12,
            textAlign: 'center',
            border: '1px solid #D1D1D1',
            borderRadius: 5,
            padding: 2,
            margin: 4,
            float: 'left',
          }}>
          <p>{file.name}</p>
          {this.fileTypeIcon(extension, file)}
          <div id="image-upload-delete-btn">
            <Button
              size="sm"
              outline
              color="danger"
              style={{ backgroundColor: '#FFF' }}
              onClick={() => this.removeFile(file)}>
              <BsFillTrashFill style={{ fontSize: 20, color: 'darkred' }} />
            </Button>
          </div>
        </div>
      );
    });
  };

  fileTypeIcon = (extension, file) => {
    if (extension === 'doc' || extension === 'docx') {
      return (
        <FaFileWord
          id="file-upload-icon"
          style={{
            fontSize: 100,
            padding: '5px 5px 10px 5px',
            cursor: 'pointer',
          }}
          onClick={() => this.downloadFile(file)}
        />
      );
    } else if (extension === 'pdf') {
      return (
        <FaFilePdf
          id="file-upload-icon"
          style={{
            fontSize: 100,
            padding: '5px 5px 10px 5px',
            cursor: 'pointer',
          }}
          onClick={() => this.downloadFile(file)}
        />
      );
    }
    if (extension === 'jpeg' || extension === 'jpg' || extension === 'png') {
      return (
        <FaFileImage
          id="file-upload-icon"
          style={{
            fontSize: 100,
            padding: '5px 5px 10px 5px',
            cursor: 'pointer',
          }}
          onClick={() => this.downloadFile(file)}
        />
      );
    }
  };

  removeFile = (file) => {
    let files = [...this.state.fileData].filter((f) => f.file !== file);
    this.props.setAttachments(files);
    this.setState({ fileData: files });
  };

  handleUploadButtonClick = () => {
    this.hiddenFileUploadRef.current.click();
  };

  render() {
    const { isJob } = this.props;

    return (
      <div>
        <FormGroup
          style={{
            marginTop: 15,
            marginBottom: 15,
            padding: 10,
            border: '1px solid #D1D1D1',
            borderRadius: 5,
          }}>
          {' '}
          {/* Using ref to customize file upload component */}
          <Input
            type="file"
            name="file"
            data-multiple-caption={''}
            multiple={true}
            onChange={this.saveFiles}
            style={{ display: 'none' }}
            innerRef={this.hiddenFileUploadRef}
          />
          <Button
            onClick={this.handleUploadButtonClick}
            style={{
              marginBottom: 2,
              backgroundColor: '#3849E3',
              border: 'none',
            }}
            disabled={this.state.propsAvailable}>
            {' '}
            <BsFolderFill /> {isJob ? 'Upload Images' : 'Upload Files'}
          </Button>
          <div style={{ display: 'flex' }}>{this.renderFileIcons()}</div>
        </FormGroup>
      </div>
    );
  }
}
