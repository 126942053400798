import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import MyJobsPage from '../pages/MyJobsPage';
import ProfilePage from '../pages/ProfilePage';
import NotFoundPage from '../pages/NotFoundPage';
import SupportPage from '../pages/SupportPage';
import JobPostingPage from '../pages/JobPostingPage';
import JobSearchPage from '../pages/JobSearchPage';
import ChatHub from './ChatHub/ChatHub';
import ClientProposalsPage from '../pages/ClientProposalsPage';
import WalletTransactionPage from '../pages/WalletTransactionPage';
import MyProposalsPage from '../pages/MyProposalsPage';
import CreateProposal from './CreateProposal';
import ViewProposal from './ViewProposal';
import Navbar from './Navbar';
import JobView from '../pages/JobView';
import AdminViewProposal from '../components/admin-ui/AdminViewProposal';
import AdminPage from '../pages/admin-ui/AdminPage';


export default class Protected extends Component {
    render() {
        const { user } = this.props;
        console.log('user');
        console.log(user);
        const { username, isAdmin, attributes } = user;
        const userId = attributes.sub;

        return (
            <div>
                <Router>
                    <Navbar username={username} userId={userId} isAdmin={isAdmin} />
                    <Switch>
                        <Redirect from="/sign-in" to="/" />
                        <Route exact path="/" component={JobSearchPage} />
                        <Route exact path="/jobs" component={MyJobsPage} />
                        <Route exact path="/jobs/search" component={JobSearchPage} />
                        <Route exact path="/jobs/create" component={JobPostingPage} />
                        <Route exact path="/jobs/:jobKey" component={JobView} />
                        <Route exact path="/jobs/:createdTs/proposals" component={ClientProposalsPage} />
                        <Route exact path="/proposals" component={MyProposalsPage} />
                        <Route exact path="/proposals/:proposalKey" component={ViewProposal} />
                        <Route exact path="/jobs/:jobKey/proposals/create" component={CreateProposal} />
                        <Route exact path="/profile/:username" component={ProfilePage} />
                        <Route exact path="/contact" component={SupportPage} />
                        <Route exact path="/proposals/:proposalKey/wallet" component={WalletTransactionPage} />
                        {isAdmin && <>
                            <Route exact path='/admin' component={AdminPage} />
                            <Route exact path='/admin/proposal/:propsalKey' component={AdminViewProposal} />
                        </>
                        }
                        <Route component={NotFoundPage} />
                    </Switch>
                </Router>
                <ChatHub user={user} />
            </div>
        );
    }
}
