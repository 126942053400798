import React, { Component } from 'react';
import Jdenticon from 'react-jdenticon';


export default class Avatar extends Component {
    render() {
        const {
            userId,
            size: propSize,
            backgroundColor
        } = this.props;

        const size = propSize || "25px";

        return (
            <div style={
                {
                    borderRadius: '50%',
                    width: size,
                    backgroundColor: backgroundColor || "#EEE",
                    display: "inline-block"
                }}>
                <div style={{ marginBottom: '1px' }}>
                    <Jdenticon value={userId} />
                </div>
            </div>
        );
    }
}
